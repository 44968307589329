<template>
	<div>
		<router-link v-if="isInternalLink" :to="to" class="link-text">
			<slot></slot>
		</router-link>
		<a v-else :href="to" class="link-text" target="_blank" rel="noopener noreferrer">
			<slot></slot>
		</a>
	</div>
</template>

<script>
export default {
	name: 'VmLink',
	props: {
		to: {
			type: String,
			required: true
		}
	},
	computed: {
		isInternalLink() {
			return this.to.startsWith('/')
		}
	}
}
</script>

<style scoped>
.link-text {
	color: #005db4;
}
.link-text:hover {
	color: #135a98;
}
</style>
