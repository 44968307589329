<template>
	<div>
		<!-- タイトル -->
		<PageTitle title-name="講師レビュー一覧" />
		<VmBox>
			<template #header>
				<v-btn color="primary" dark class="mb-2" @click="openAddForm()"> 新規レビュー追加 </v-btn>
			</template>
			<template #content>
				<!-- 各人のレビュー一覧 -->
				<div class="regular-schedule-list-area" v-if="teacherReviews.length > 0" :style="{ padding: '15px 15px' }">
					<div v-for="(reviewItem, index) of teacherReviews" :key="reviewItem.teacherId">
						<v-row>
							<v-col cols="12" xs="6" sm="6" md="3" lg="3">
								<!-- 講師番号 -->
								{{ reviewItem.teacherId }} -
								<!-- 名前 -->
								{{ reviewItem.teacherName }} 先生

								<!-- レビューがない場合は「レビューがありません」と出す -->
								<div v-if="!reviewItem.reviews.length">
									<p class="noReview">レビューがありません</p>
								</div>
								<!-- 「詳細を見る」ボタン -->
								<v-btn
									v-else
									color="primary"
									text
									@click="openDetail(index)"
									:style="{
										position: 'relative',
										right: '10px',
										bottom: '2px'
									}"
								>
									詳細を見る
									<v-icon small> mdi-chevron-down </v-icon>
								</v-btn>
							</v-col>

							<v-col cols="6" xs="6" sm="3" md="3" lg="3">
								<v-btn color="primary" dark class="mb-2" @click="openAddForm(reviewItem.teacherId)">
									レビュー追加
								</v-btn>
							</v-col>
							<v-spacer />
						</v-row>
						<!-- レビュー詳細テーブル -->
						<v-row>
							<v-col>
								<v-data-table
									:headers="headers"
									:items="reviewItem.reviews"
									:class="{ 'elevation-1': true }"
									disable-sort
									disable-pagination
									no-data-text="レビューはありません"
									hide-default-footer
									v-show="computedShowDataTable(index)"
									:loading="isLoading"
								>
									<template v-slot:[`item.actions`]="{ item }">
										<v-icon small @click="openUpdateForm(item)" :style="{ marginRight: '12px' }"> mdi-pencil </v-icon>
										<v-icon small @click="deleteTeacherReview(item)"> mdi-delete </v-icon>
									</template>
									<template v-slot:[`item.image`]="{ item }">
										<img :src="item.imageUrl" class="table-img" />
									</template>
								</v-data-table>
							</v-col>
						</v-row>
					</div>
				</div>
				<div class="regular-schedule-list-area" v-else>
					<div v-if="isLoading">
						<vue-loading type="spin" color="#DCC268" :size="{ width: '50px', height: '50px' }"></vue-loading>
					</div>
					<v-row justify="center" v-else>
						<p class="no-data-text">表示するレビューデータがありません</p>
					</v-row>
				</div>
			</template>
		</VmBox>
		<v-dialog v-model="showAddDialog" v-if="showAddDialog" max-width="500px">
			<AdminAddFormCard2
				@closeAction="closeAdd"
				@addAction="addReview"
				:editedItem="addedItem"
				formTitle="新規レビュー追加"
				@changeFile="changeFile($event)"
				:loading="isLoading"
			/>
		</v-dialog>
		<v-dialog v-model="showUpdateDialog" v-if="showUpdateDialog" max-width="500px">
			<AdminAddFormCard2
				@closeAction="closeEdit"
				@addAction="updateTeacherReview"
				:editedItem="addedItem"
				formTitle="レビュー編集"
				@changeFile="changeFile($event)"
				:loading="isLoading"
			/>
		</v-dialog>
	</div>
</template>

<script>
import AdminAddFormCard2 from '../Organisms/AdminAddFormCard2'
import PageTitle from '../Atoms/PageTitle'
import VmBox from '../Atoms/VmBox'
import { mapGetters } from 'vuex'

export default {
	name: 'AdminTeacherReview',
	components: {
		AdminAddFormCard2,
		PageTitle,
		VmBox
	},
	data: () => ({
		teacherReviews: [],
		addedItem: {
			id: {
				value: null,
				type: '',
				required: false,
				label: 'id'
			},
			teacherId: {
				value: null,
				type: 'number',
				required: true,
				label: '講師id'
			},
			reviewedAt: {
				value: null,
				type: 'date',
				required: true,
				label: 'レビュー日付'
			},
			studentName: {
				value: null,
				type: 'textField',
				required: false,
				label: '生徒名'
			},
			lessonCourseId: {
				value: null,
				type: 'number',
				required: true,
				label: 'コースID（1:会話, 2:プライベート, 3:テキスト, 4:少人数, 5:動画）'
			},
			age: {
				value: null,
				type: 'number',
				required: true,
				label: '年齢'
			},
			residence: {
				value: null,
				type: 'textField',
				required: true,
				label: '住まい'
			},
			occupation: {
				value: null,
				type: 'textField',
				required: true,
				label: '職業'
			},
			learningGoal: {
				value: null,
				type: 'textarea',
				required: true,
				label: '学習の目標'
			},
			lessonFrequency: {
				value: null,
				type: 'textField',
				required: true,
				label: 'レッスン受講頻度'
			},
			successExams: {
				value: null,
				type: 'textField',
				required: true,
				label: '試験合格'
			},
			impressions: {
				value: [],
				type: 'checkbox-group',
				required: true,
				label: '講師の印象',
				options: [
					{ label: '質問しやすい', value: '質問しやすい' },
					{
						label: '優しい、学習者に寄り添ってくれる',
						value: '優しい、学習者に寄り添ってくれる'
					},
					{ label: '対応が丁寧', value: '対応が丁寧' },
					{ label: '熱意がある', value: '熱意がある' },
					{ label: 'プロ意識が高い', value: 'プロ意識が高い' },
					{ label: '成長を促してくれる', value: '成長を促してくれる' },
					{ label: 'アドバイスが的確', value: 'アドバイスが的確' },
					{
						label: 'レッスン外学習（宿題など）へのフォローが丁寧',
						value: 'レッスン外学習（宿題など）へのフォローが丁寧'
					}
				],
				row: 1
			},
			content: {
				value: null,
				type: 'textarea',
				required: true,
				label: 'レビュー内容'
			}
		},
		showAddDialog: false,
		showUpdateDialog: false,
		openedIndexArray: [],
		isShowDataTable: true,
		isLoading: false,
		uploadedImage: null
	}),
	computed: {
		...mapGetters({
			userInfo: 'user/getUserInfo'
		}),
		headers() {
			return [
				{ text: 'Actions', value: 'actions', sortable: false },
				{
					text: 'teacherReviewId',
					align: 'start',
					value: 'id'
				},
				{
					text: '画像',
					value: 'image'
				},
				{
					text: 'レビュー日付',
					value: 'reviewedAt'
				},
				{
					text: '生徒名',
					value: 'studentName'
				},
				{
					text: 'レッスンコース',
					value: 'lessonCourse'
				},
				{
					text: '年齢',
					value: 'age'
				},
				{
					text: '住まい',
					value: 'residence'
				},
				{
					text: '職業',
					value: 'occupation'
				},
				{
					text: '学習の目標',
					value: 'learningGoal'
				},
				{
					text: 'レッスン受講頻度',
					value: 'lessonFrequency'
				},
				{
					text: '試験合格',
					value: 'successExams'
				},
				{
					text: '講師の印象',
					value: 'impressions'
				},
				{
					text: 'レビュー内容',
					value: 'content'
				}
			]
		},
		computedShowDataTable: function () {
			return function (index) {
				if (!this.isShowDataTable) {
					return false
				}
				if (this.openedIndexArray.indexOf(index) != -1) {
					return true
				} else {
					return false
				}
			}
		}
	},
	props: [],
	created() {
		this.reloadTable()
	},
	mounted() {},
	methods: {
		async reloadTable() {
			try {
				await this.fetchTeacherReviews()
			} catch (error) {
				console.log('error', error)
			}
			this.isShowDataTable = false

			await this.$nextTick()
			this.connectionDataList = []

			this.isShowDataTable = true
		},
		fetchTeacherReviews() {
			this.isLoading = true
			return new Promise((resolve, reject) => {
				const header = {
					headers: {
						Authorization: `Bearer ${this.userInfo.accessToken}`
					}
				}
				this.axios
					.get(`/api/teacherReviews`, header)
					.then((response) => {
						this.teacherReviews = response.data

						for (let teacherReview of this.teacherReviews) {
							for (let review of teacherReview.reviews) {
								review.lessonCourse = this.$t(`labels.lessonCourses.${review.lessonCourseId}`)
								if (review.reviewedAt) {
									review.reviewedAt = review.reviewedAt.split('T')[0]
								}
								// impressionsを配列に変換
								if (!review.impressions) {
									review.impressions = []
								} else if (typeof review.impressions === 'string') {
									review.impressions = review.impressions.split(',')
								}
							}
						}
						this.isLoading = false
						resolve()
					})
					.catch((error) => {
						this.isLoading = false
						console.log(error)
						reject()
					})
			})
		},
		openDetail(index) {
			if (this.computedShowDataTable(index)) {
				// 既に開いている場合はindexを消す
				const deletedIndex = this.openedIndexArray.indexOf(index)
				this.openedIndexArray.splice(deletedIndex, 1)
			} else {
				// まだ開かれていない時はindexを追加する
				this.openedIndexArray.push(index)
			}
		},
		openAddForm(teacherId) {
			console.log('teacherId', teacherId)
			if (teacherId) {
				this.addedItem.teacherId.value = teacherId
			}
			this.showAddDialog = true
		},
		openUpdateForm(item) {
			this.addedItem.teacherId.value = item.teacherId

			for (let key in item) {
				if (Object.prototype.hasOwnProperty.call(this.addedItem, key) && item[key] !== undefined) {
					this.addedItem[key].value = item[key]
				}
			}
			this.showUpdateDialog = true
		},
		closeAdd() {
			this.showAddDialog = false
			this.resetAddedItem()
		},
		closeEdit() {
			this.showUpdateDialog = false
			this.resetAddedItem()
		},
		resetAddedItem() {
			for (let key in this.addedItem) {
				if (key === 'impressions') {
					this.addedItem[key].value = []
				} else {
					this.addedItem[key].value = null
				}
			}
			this.uploadedImage = null
		},
		changeFile(elem) {
			this.uploadedImage = ''
			const files = elem.target.files || elem.dataTransfer.files
			this.uploadedImage = files[0]
		},
		createFormData(data) {
			let formData = new FormData()
			for (const [key, object] of Object.entries(data)) {
				if (object.value != null) {
					if (key === 'impressions' && Array.isArray(object.value)) {
						formData.append(key, object.value.join(','))
					} else {
						formData.append(key, object.value)
					}
				}
			}
			return formData
		},
		async addReview() {
			this.isLoading = true
			let formData = this.createFormData(this.addedItem)
			formData.append('image', this.uploadedImage)

			const header = {
				headers: {
					Authorization: `Bearer ${this.userInfo.accessToken}`,
					'Content-Type': 'multipart/form-data'
				}
			}

			try {
				await this.axios.post(`api/teacherReviews`, formData, header)
				this.isLoading = false
				this.resetAddedItem()
				this.closeAdd()
				this.reloadTable()
			} catch (error) {
				this.isLoading = false
				alert(error.response.data.error.message)
			}
		},
		async updateTeacherReview() {
			this.isLoading = true

			let formData = this.createFormData(this.addedItem)
			formData.append('image', this.uploadedImage)

			const header = {
				headers: {
					Authorization: `Bearer ${this.userInfo.accessToken}`,
					'Content-Type': 'multipart/form-data'
				}
			}

			try {
				await this.axios.put(`api/teacherReviews/${this.addedItem.id.value}`, formData, header)
				this.resetAddedItem()
				this.closeEdit()
				this.reloadTable()
				this.isLoading = false
			} catch (error) {
				alert(error.response.data.error.message)
				this.isLoading = false
			}
		},
		deleteTeacherReview(item) {
			this.isLoading = true
			console.log('item', item)

			if (confirm('レビューを削除していいですか？')) {
				const header = {
					headers: {
						Authorization: `Bearer ${this.userInfo.accessToken}`
					}
				}

				// teacherReviewIdを指定して削除
				this.axios
					.delete(`/api/teacherReviews/${item.id}`, header)
					.then(() => {
						this.isLoading = false
						this.reloadTable()
					})
					.catch((error) => {
						this.isLoading = false
						alert(error.response.data.error.message)
					})
			}
		}
	}
}
</script>

<style lang="scss" scoped>
v-data-table {
	width: 100%;
	border-collapse: collapse;
	border-spacing: 0;
	background-color: #dcc268;
}

thead.v-data-table-header {
	background-color: #dcc268;
}

.table-img {
	width: 100px;
	height: 100px;
	object-fit: cover;
}

.v-data-table {
	white-space: nowrap;
}
.regular-schedule-list-area {
	max-height: 700px;
	overflow: auto;
}
.noReview {
	font-size: 0.8em;
	margin: 0;
	color: #6d6f6c;
}
</style>
