<template>
	<validationProvider :rules="rules" v-slot="{ errors }" name="レッスンで学びたいこと">
		<p>
			<FormTitleText titleText="レッスンで学びたいこと" />
			<RequiredIcon v-if="rules && rules.includes('required')" />
			<FormAttentionText attentionText="※複数選択可" />
		</p>
		<v-row>
			<v-col cols="6" v-for="hopeInLessonItem of hopeInLessonItems" :key="hopeInLessonItem">
				<v-checkbox
					v-model="inputedHopeInLessons"
					:label="hopeInLessonItem"
					:value="hopeInLessonItem"
					color="#DCC268"
					hide-details
				>
					<!-- チェックボックスのラベルの大きさを変える方法 -->
					<template v-slot:label>
						<span :style="isPC ? { 'font-size': '0.9em' } : { 'font-size': '0.7em' }">{{ hopeInLessonItem }}</span>
					</template>
				</v-checkbox>
			</v-col>
		</v-row>
		<FormErrorText :errorText="errors[0]" />
	</validationProvider>
</template>

<script>
import FormTitleText from '../Atoms/FormTitleText'
import FormErrorText from '../Atoms/FormErrorText'
import FormAttentionText from '../Atoms/FormAttentionText'
import RequiredIcon from '../Atoms/RequiredIcon'

export default {
	name: 'HopeInLessonForm',
	data: () => ({
		hopeInLessonItems: [
			'文法/Grammatik',
			'読解/Lesen',
			'作文添削/schriftlicher Ausdruck',
			'リスニング/Hörverständnis',
			'発音/Aussprache',
			'会話/Konversation',
			'試験対策/Prüfungsvorbereitung'
		]
	}),
	props: ['value', 'rules'],
	mounted() {},
	computed: {
		inputedHopeInLessons: {
			get() {
				if (!this.value) return []
				return this.value.split(',')
			},
			set(value) {
				// 選択された項目をカンマ区切りの文字列に変換
				const combinedHopeInLesson = value.filter((item) => item && item !== 'null').join(',')

				this.$emit('input', combinedHopeInLesson)
			}
		}
	},
	methods: {},
	components: {
		FormTitleText,
		FormErrorText,
		FormAttentionText,
		RequiredIcon
	}
}
</script>

<style lang="scss" scoped></style>
