<template>
	<div>
		<PageTitle title-name="会話コースチケット一覧" />
		<vm-text-field v-model="search" label-text="検索" class="searchTextField" />
		<v-data-table
			class="dataTable"
			:headers="allConversationTicketHeaders"
			:items="filteredItems"
			:class="{ 'elevation-1': true }"
			hide-default-footer
			disable-pagination
			disable-sort
			:loading="adminConversationTicketsLoading"
			no-data-text="チケット情報はありません"
			width="20px"
		/>
	</div>
</template>

<script>
import PageTitle from '../Atoms/PageTitle'
import { mapGetters, mapActions } from 'vuex'
import VmTextField from '../Atoms/VmTextField'

export default {
	name: 'AdminConversationTicketsList',
	props: {},
	components: {
		PageTitle,
		VmTextField
	},
	data: () => ({
		search: ''
	}),
	created() {
		this.fetchAllTickets({
			accessToken: this.userInfo.accessToken
		})
	},
	mounted() {},
	computed: {
		...mapGetters({
			userInfo: 'user/getUserInfo',
			adminConversationTicketsLoading: 'adminConversationTickets/loading',
			allConversationTickets: 'adminConversationTickets/allConversationTickets'
		}),
		allConversationTicketHeaders() {
			return [
				{ text: 'id', value: 'id' },
				{ text: '生徒Id', value: 'studentId' },
				{ text: '生徒名', value: 'studentName' },
				{ text: '現在の所持数', value: 'currentTicketCount' },
				{ text: 'フィードバック', value: 'isFeedback' },
				{ text: '有効期限', value: 'expirationDate' },
				{ text: '追加チケット枚数', value: 'purchasedTicketCount' },
				{ text: '追加日', value: 'purchaseDate' },
				{ text: 'ステータス', value: 'status' }
			]
		},
		filteredItems() {
			console.log('this.allConversationTicketItems.length', this.allConversationTicketItems.length)
			if (!this.search) {
				return this.allConversationTicketItems // 検索テキストが空の場合は全アイテムを返す
			}
			return this.allConversationTicketItems.filter((item) => {
				return Object.values(item).some((value) => {
					return String(value).toLowerCase().includes(this.search.toLowerCase())
				})
			})
		},
		allConversationTicketItems() {
			if (!this.allConversationTickets) return []

			return this.allConversationTickets.map((ticket) => {
				return {
					id: ticket.id,
					studentId: ticket.studentId,
					studentName: ticket.studentName,
					currentTicketCount: ticket.currentTicketCount,
					isFeedback: ticket.isFeedback ? 'あり' : '-',
					expirationDate: this.$moment(ticket.expirationDate).format('YYYY/MM/DD HH:mm:ss'),
					purchasedTicketCount: ticket.purchasedTicketCount,
					purchaseDate: this.$moment(ticket.purchaseDate).format('YYYY/MM/DD HH:mm:ss'),
					status: ticket.isAvailable ? '有効' : '-'
				}
			})
		}
	},
	methods: {
		...mapActions({
			fetchAllTickets: 'adminConversationTickets/fetchAllTickets'
		})
	}
}
</script>

<style lang="scss" scoped>
.searchTextField {
	margin-bottom: 20px;
	max-width: 500px;
}
.dataTable {
	margin-bottom: 32px;
}
</style>
