import axios from 'axios'

export const adminStudent = {
	namespaced: true,
	state: {
		students: null,
		meta: null,
		loading: false
	},
	getters: {
		students: (state) => {
			return state.students
		},
		meta: (state) => {
			return state.meta
		},
		loading: (state) => {
			return state.loading
		}
	},
	mutations: {
		prepareConnect(state) {
			state.loading = true
		},
		stopLoading(state) {
			state.loading = false
		},
		setStudents(state, payload) {
			state.students = payload
			state.loading = false
		},
		setMeta(state, payload) {
			state.meta = payload
		}
	},
	actions: {
		async fetchStudents({ commit }, payload) {
			commit('prepareConnect')
			const { accessToken, page, searchQuery } = payload
			const header = {
				headers: {
					Authorization: `Bearer ${accessToken}`
				}
			}

			const params = {
				perPage: 50,
				...(page && { page }),
				...(searchQuery && { searchQuery })
			}

			return axios
				.get('/api/students', { headers: header.headers, params })
				.then((res) => {
					commit('setStudents', res.data.students)
					commit('setMeta', res.data.meta)
				})
				.catch(() => {
					commit('stopLoading')
				})
		}
	}
}
