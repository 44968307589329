import axios from 'axios'

export const adminConversationTickets = {
	namespaced: true,
	state: {
		allConversationTickets: null,
		loading: false
	},
	getters: {
		loading: (state) => {
			return state.loading
		},
		allConversationTickets: (state) => {
			return state.allConversationTickets
		}
	},
	mutations: {
		prepareConnect(state) {
			state.loading = true
		},
		stopLoading(state) {
			state.loading = false
		},
		setAllConversationTicketsData(state, data) {
			state.allConversationTickets = data
		}
	},
	actions: {
		async fetchAllTickets({ commit }, payload) {
			commit('prepareConnect')
			const { accessToken } = payload

			const header = {
				headers: {
					Authorization: `Bearer ${accessToken}`
				}
			}
			return axios
				.get(`/api/conversation-lesson-tickets`, header)
				.then((res) => {
					commit('setAllConversationTicketsData', res.data)
				})
				.finally(() => {
					commit('stopLoading')
				})
		}
	}
}
