<template>
	<div>
		<SubHeading sub-heading-text="所持会話チケット枚数" class="subheading" />
		<span class="ticket-num"> {{ totalAvailableTicketCount }}</span
		>枚
		<VmLink to="/availableConversationTicketsList" class="ticketDetailLink">所持チケットの詳細</VmLink>
		<ActiveButtonWithArrow button-title="会話チケットを追加購入する" @clickAction="toBuyTicket" />
	</div>
</template>

<script>
import SubHeading from '@/components/Atoms/SubHeading.vue'
import ActiveButtonWithArrow from '@/components/Atoms/ActiveButtonWithArrow.vue'
import VmLink from '@/components/Atoms/VmLink.vue'
import { mapGetters, mapActions } from 'vuex'

export default {
	name: 'StudentTopTicketArea',
	props: {},
	components: {
		SubHeading,
		ActiveButtonWithArrow,
		VmLink
	},
	data: () => ({}),
	created() {
		this.fetchConversationTickets({
			accessToken: this.userInfo.accessToken,
			studentId: this.userInfo.studentId
		})
	},
	mounted() {},
	computed: {
		...mapGetters({
			userInfo: 'user/getUserInfo',
			conversationTicketsLoading: 'conversationTickets/loading',
			totalAvailableTicketCount: 'conversationTickets/totalAvailableTicketCount'
		})
	},
	methods: {
		...mapActions({
			fetchConversationTickets: 'conversationTickets/fetchConversationTickets'
		}),
		toBuyTicket() {
			this.$router.push({ path: 'buyTicketTop' })
		}
	}
}
</script>

<style lang="scss" scoped>
.subheading {
	margin: 20px 0 0 0;
}
.ticketDetailLink {
	margin-bottom: 20px;
}
.ticket-num {
	color: #dcc268;
	font-size: 4em;
	font-weight: bold;
}
</style>
