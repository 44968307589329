import { render, staticRenderFns } from "./AdminAddFormCard2.vue?vue&type=template&id=7c9206f2&scoped=true&"
import script from "./AdminAddFormCard2.vue?vue&type=script&lang=js&"
export * from "./AdminAddFormCard2.vue?vue&type=script&lang=js&"
import style0 from "./AdminAddFormCard2.vue?vue&type=style&index=0&id=7c9206f2&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7c9206f2",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTextarea } from 'vuetify/lib/components/VTextarea';
installComponents(component, {VCheckbox,VSelect,VTextField,VTextarea})
