<template>
	<v-card>
		<v-card-title>
			<v-col>
				<v-row justify="center">
					<span class="headline">{{ formTitle }}</span>
				</v-row>
			</v-col>
		</v-card-title>
		<v-card-text>
			<v-container>
				<v-row>
					<!-- レッスン情報 -->
					<p class="report-subtitle">{{ $t('top.lessonInfo') }}</p>
					<TimeZoneIcon />
				</v-row>
				<v-row>
					<!-- 生徒名 -->
					{{ $t('top.studentName') }}: {{ lessonInfo.studentName }}
				</v-row>
				<v-row>
					<!-- 開始時間 -->
					{{ $t('top.startTime') }} {{ lessonInfo.startTime }}
				</v-row>
				<v-row>
					<!-- 終了時間 -->
					{{ $t('top.endTime') }} {{ lessonInfo.endTime }}
				</v-row>
			</v-container>
			<v-container>
				<v-row>
					<!-- 報告カテゴリ - 完了orキャンセルを選ぶところ -->
					<p class="report-subtitle">
						{{ $t('top.reportCategory') }}
						<span class="attention">{{ $t('top.required') }}</span>
					</p>
				</v-row>
				<v-row>
					<v-select
						:items="reportCategoryItems"
						v-model="inputData.status"
						:label="$t('top.requireSelection')"
						solo
						hide-details
					></v-select>
				</v-row>
			</v-container>
			<!-- 講師用メモ プライベートかつ完了時に表示-->
			<v-container v-if="isPrivate && inputData.status === this.$t('top.completed')">
				<v-row>
					<p class="report-subtitle">
						{{ $t('top.memoForTeacher') }}
					</p>
				</v-row>
				<!-- 授業内容や宿題範囲などのメモとしてご自由にお使いください。※こちらのメモは生徒には送信・表示されません。 -->
				<v-row>
					<p class="attention">
						{{ $t('top.memoExplanation') }}
						<br />
						<span class="red--text">{{ $t('top.memoAttention') }}</span>
					</p>
				</v-row>
				<v-row>
					<v-textarea
						v-model="inputData.memoForTeacher"
						:placeholder="$t('top.memoPlaceHolder')"
						solo
						counter
					></v-textarea>
				</v-row>
			</v-container>
			<!-- 初回のプライベートレッスンかつ、「完了」を選択した時に表示させる -->
			<v-container v-if="inputData.status === this.$t('top.completed') && isPrivate && lessonInfo.isFirstLesson">
				<v-row>
					<p class="report-subtitle">
						{{ $t('top.lessonPlan') }}
						<span class="attention">{{ $t('top.required') }}</span>
					</p>
				</v-row>
				<VmAccordionMenu
					:text="$t('top.lessonPlanInfo')"
					@click="showLessonPlanInfo = !showLessonPlanInfo"
					:style="{ 'margin-left': '-8px' }"
				/>
				<div v-if="showLessonPlanInfo">
					<p>
						🗒Unterrichtsplan<br />Vorlage herunterladen:<br />
						<a
							href="https://docs.google.com/document/d/1Gb-n0kE59BgKRcI66M2TV8Enmq8yjrHTHU1ieYNzERg/copy"
							target="_blank"
							rel="noopener"
							>https://docs.google.com/document/d/1Gb-n0kE59BgKRcI66M2TV8Enmq8yjrHTHU1ieYNzERg/copy
						</a>
						<br /><br />
						👀Beispiel<br />・Deutsch: <br />
						<a
							href="https://docs.google.com/document/d/1zvDzDZ24MSUxfky7N_bcxpDGF0fADNLgqT_9TlmGFFY/edit?usp=sharing"
							target="_blank"
							rel="noopener"
							>https://docs.google.com/document/d/1zvDzDZ24MSUxfky7N_bcxpDGF0fADNLgqT_9TlmGFFY/edit?usp=sharing
						</a>
						<br />・日本語: <br />
						<a
							href="https://docs.google.com/document/d/1oXJozcg6rnJPrTsSANztBxHKT7pmg2m6jAsJ20tKKi4/edit?usp=sharing"
							target="_blank"
							rel="noopener"
							>https://docs.google.com/document/d/1oXJozcg6rnJPrTsSANztBxHKT7pmg2m6jAsJ20tKKi4/edit?usp=sharing
						</a>
						<br /><br />
						🖋Ziel des Plans<br />Sowohl Lehrer als auch Schüler haben einen gemeinsamen Ausblick auf den zukünftigen
						Unterricht und wissen grob, wie eine Unterrichtseinheit abläuft. *Natürlich könnt bzw. solltet ihr den Plan
						je nach Situation und nach Rücksprache mit dem Schüler verändern.
					</p>
				</div>
				<!-- 計画書の提出の有無 -->
				<v-row>
					<p class="report-sub-subtitle">
						{{ $t('top.doYouSubmitLessonPlan') }}
						<br />
						<!-- 原則提出は必須です。-->
						<span class="red--text submit-repot-attention">{{ $t('top.submitLessonAttention') }}</span>
					</p>
				</v-row>
				<v-row>
					<v-select
						:items="submitReportItems"
						v-model="submitReport"
						:label="$t('profile.requireSelection')"
						solo
						hide-details
						@change="resetReportSelection"
					></v-select>
				</v-row>
				<div v-if="submitReport === $t('top.yesSubmit')">
					<v-row>
						<p class="report-sub-subtitle">{{ $t('top.upload') }}</p>
					</v-row>
					<v-row>
						<!-- レッスン計画書添付ボタン -->
						<form class="form">
							<input
								@change="changeLessonPlanFile"
								id="lessonPlan"
								type="file"
								name="upfile[]"
								:multiple="false"
								accept="application/pdf"
							/>
						</form>
						<!-- この計画書は、この後自動で生徒＆事務局に送信されます。-->
						<p class="red--text" v-if="inputData.lessonPlanPdf != ''">
							<span class="attention">{{ $t('top.fileAttention') }}</span>
						</p>
						<!-- 最終チェック -->
						<p class="red--text" v-if="inputData.lessonPlanPdf != ''">
							<span class="attention">{{ $t('top.finalCheckForReport') }}</span>
						</p>
					</v-row>
				</div>
				<div v-if="submitReport === $t('top.noSubmit')">
					<!-- 必要ない理由 -->
					<v-row>
						<p class="report-sub-subtitle">{{ $t('top.unnecessaryReason') }}</p>
					</v-row>
					<v-row>
						<v-select
							:items="reasonForUnnecessaryItems"
							v-model="inputData.reasonForUnnecessary"
							:label="$t('top.requireSelection')"
							solo
							hide-details
						></v-select>
					</v-row>
					<!-- 何か別の理由がある際は、 -->
					<v-row>
						<p class="attention">
							{{ $t('top.otherReasonText') }}
						</p>
					</v-row>
				</div>
			</v-container>
			<v-container v-if="inputData.status === this.$t('top.completed') && !isPrivate">
				<v-row>
					<!-- 報告内容 -->
					<p class="report-subtitle">
						{{ $t('top.content') }}
						<span class="attention">{{ $t('top.required') }}</span>
					</p>
					<!-- ※報告内容は講師間での情報共有に使用します。生徒には送信されません。 -->
					<p class="content-attention">
						{{ $t('top.contentAttention') }}
					</p>
				</v-row>
				<v-row>
					<v-textarea
						v-model="inputData.lessonReport"
						:placeholder="$t('top.reportPlaceHolder')"
						solo
						counter
					></v-textarea>
				</v-row>
			</v-container>
			<v-container v-if="showFeedback">
				<v-row>
					<!-- 生徒へのフィードバック -->
					<p class="report-subtitle">
						{{ $t('top.feedBack') }}
						<br />
					</p>
					<!-- ※FBはこの後直接生徒へメールで送信されます。~~~ -->
					<p class="feedback-attention">
						{{ $t('top.feedBackAttention') }}
					</p>
				</v-row>
				<v-row>
					<!-- いいところ -->
					<p class="feedback-subtitle">
						{{ $t('top.goodPoint') }}
						<span class="attention">{{ $t('top.required') }}</span>
					</p>
				</v-row>
				<v-row>
					<v-textarea
						v-model="inputData.lessonFeedbackGood"
						:placeholder="$t('top.goodPointPlaceHolder')"
						solo
						counter
					></v-textarea>
				</v-row>
				<v-row>
					<!-- もっとよくなるところ -->
					<p class="feedback-subtitle">
						{{ $t('top.getBetterPoint') }}
						<span class="attention">{{ $t('top.required') }}</span>
					</p>
				</v-row>
				<v-row>
					<v-textarea
						v-model="inputData.lessonFeedbackImprovement"
						:placeholder="$t('top.getBetterPointPlaceHolder')"
						solo
						counter
					></v-textarea>
				</v-row>
			</v-container>
		</v-card-text>
		<v-card-actions>
			<v-row justify="center">
				<v-col cols="7" class="button-area">
					<v-row justify="center">
						<ActiveButton
							:button-title="$t('top.reportButton')"
							@clickAction="report"
							class="button"
							:isDisabled="!buttonValidation"
						/>
					</v-row>
					<v-row justify="center">
						<CancelButton :button-title="$t('top.closeForm')" @clickAction="close" class="button" />
					</v-row>
				</v-col>
			</v-row>
		</v-card-actions>
	</v-card>
</template>

<script>
import ActiveButton from '../Atoms/ActiveButton'
import CancelButton from '../Atoms/CancelButton'
import TimeZoneIcon from '../Atoms/TimeZoneIcon'
import VmAccordionMenu from '../Atoms/VmAccordionMenu'

export default {
	name: 'LessonReportCard',
	components: {
		ActiveButton,
		CancelButton,
		TimeZoneIcon,
		VmAccordionMenu
	},
	data: () => ({
		tmpAddItem: {},
		reportCategoryItems: [],
		tmpReportForm: {},
		submitReportItems: [],
		submitReport: '',
		reasonForUnnecessaryItems: [],
		VmAccordionMenu,
		showLessonPlanInfo: false
	}),
	computed: {
		inputData: {
			get() {
				return this.reportForm
			},
			set(value) {
				this.tmpReportForm = value
				this.$emit('update:reportForm', this.tmpReportForm)
			}
		},
		buttonValidation() {
			if (!this.inputData.status) {
				return false
			}
			// キャンセルの場合はok
			if (this.inputData.status === this.$t('top.canceledLesson')) {
				return true
			}
			// 完了、かつプライベートコースの場合
			if (this.inputData.status === this.$t('top.completed') && this.isPrivate) {
				// 初回授業の場合は、「PDFアップロードがされている」or「必要のない理由が書かれている」以外はダメ
				if (this.lessonInfo.isFirstLesson) {
					if (this.inputData.lessonPlanPdf === '' && this.inputData.reasonForUnnecessary === '') {
						return false
					}
				}
				// 初回授業以外の場合は基本的にはok
				return true
			}
			// 「完了」の場合は必ず内容報告を書かないとだめ
			if (this.inputData.lessonReport === '') {
				return false
			}
			// FB希望者にFBしないのはダメ
			if (this.lessonInfo.isFeedback) {
				if (this.inputData.lessonFeedbackGood === '' || this.inputData.lessonFeedbackImprovement === '') {
					return false
				}
			}
			return true
		},
		showFeedback() {
			// プライベートの時は表示しない
			if (this.isPrivate) {
				return false
			}
			if (this.inputData.status === this.$t('top.completed') && this.lessonInfo.isFeedback) {
				return true
			} else {
				return false
			}
		},
		isConversation() {
			if (this.lessonInfo.lessonCourseId === 1) {
				return true
			} else {
				return false
			}
		},
		isPrivate() {
			if (this.lessonInfo.lessonCourseId === 2) {
				return true
			} else {
				return false
			}
		}
	},
	props: ['reportForm', 'formTitle', 'lessonInfo'],
	created() {
		this.reportCategoryItems.push(this.$t('top.completed'))
		this.reportCategoryItems.push(this.$t('top.canceledLesson'))
	},
	mounted() {
		this.initSelection()
	},
	methods: {
		initSelection() {
			this.submitReportItems = []
			this.submitReportItems.push(this.$t('top.yesSubmit'))
			this.submitReportItems.push(this.$t('top.noSubmit'))

			this.reasonForUnnecessaryItems = []
			this.reasonForUnnecessaryItems.push(this.$t('top.reasonForUnnecessary1'))
			this.reasonForUnnecessaryItems.push(this.$t('top.reasonForUnnecessary2'))
			this.reasonForUnnecessaryItems.push(this.$t('top.reasonForUnnecessary3'))
		},
		close() {
			this.$emit('closeAction')
		},
		report() {
			this.$emit('reportAction')
		},
		changeLessonPlanFile(elem) {
			const files = elem.target.files || elem.dataTransfer.files
			if (elem.target.files[0].size > 20000000) {
				alert('添付できる最大サイズ(20MB)を超えています')
				const fileInput = document.getElementById('lessonPlan')
				fileInput.value = ''
			} else {
				this.inputData.lessonPlanPdf = files[0]
			}
		},
		// 選択肢を変更する際は添付画像と必要ない文言は削除する
		resetReportSelection() {
			this.inputData.lessonPlanPdf = ''
			this.inputData.reasonForUnnecessary = ''
		}
	}
}
</script>

<style lang="scss" scoped>
p {
	white-space: pre-line;
}
.report-subtitle {
	font-size: 1.4em;
	font-weight: bold;
	margin: 20px 0 15px 0;
}
.report-sub-subtitle {
	font-size: 1em;
	font-weight: bold;
	margin: 20px 0 0 0;
}
.container {
	margin: 5px 0 20px 0;
}
.button-area {
	margin: 15px 0;
}
.button {
	margin: 0 0 10px 0;
}
.attention {
	font-size: 0.8em;
	font-weight: normal;
}
.content-attention {
	padding: 0 0 2px 0;
}
.feedback-attention {
	padding: 0 0 2px 0;
}
.feedback-subtitle {
	margin: 0;
	font-weight: bold;
}
.submit-repot-attention {
	font-size: 0.8em;
	font-weight: normal;
}
</style>
