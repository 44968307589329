<template>
	<v-container>
		<v-hover disabled>
			<v-data-table
				v-model="selected"
				:headers="headers"
				:items="plannedLessonArray"
				:page.sync="page"
				:items-per-page="itemsPerPage"
				:class="{ 'elevation-1': true, hoverPointer: hoverPointer }"
				hide-default-footer
				disable-sort
				:no-data-text="$t('top.noLesson')"
				@click:row="clickRow"
				:loading="plannedTeacherLessonsLoading"
			>
				<template v-slot:top class="not-yubi">
					<!-- （講師）完了報告ダイアログ -->
					<v-dialog v-model="showReportDialog" v-if="showReportDialog" max-width="500px" persistent>
						<LessonReportCard
							@closeAction="closeReportCard"
							@reportAction="report"
							:formTitle="selectedItem.isFirstLesson ? $t('top.reportFirstLesson') : $t('top.reportLesson')"
							:lessonInfo="selectedItem"
							:reportForm="reportForm"
						/>
					</v-dialog>
					<!-- （講師）レッスン削除依頼ダイアログ -->
					<v-dialog v-model="showRequestDeleteDialog" v-if="showRequestDeleteDialog" max-width="500px">
						<RequestDeleteLessonCard
							@close="closeRequestDeleteLessonCard"
							@reload="reload"
							:formTitle="$t('top.requestDeleteLesson.title')"
							:lessonInfo="selectedItem"
						/>
					</v-dialog>
					<!-- （講師）レッスン詳細ダイアログ -->
					<v-dialog v-model="showDetailDialog" v-if="showDetailDialog" max-width="500px">
						<LessonDetailCard
							@closeAction="closeDetailCard"
							:formTitle="$t('top.lessonDetail')"
							:lessonInfo="selectedItem"
							:accessToken="userInfo.accessToken"
						/>
					</v-dialog>
					<!-- レッスンキャンセルダイアログ -->
					<v-dialog v-model="showCancelDialog" v-if="showCancelDialog" max-width="500px">
						<LessonCancelCard
							@closeAction="closeCancelCard"
							@cancelAction="cancelLesson"
							:formTitle="$t('top.cancelLessonTitle')"
							:lessonInfo="selectedItem"
							:cancelForm="cancelForm"
							:isTeacher="true"
						/>
					</v-dialog>
					<v-dialog v-model="showCancelDisableDialog" v-if="showCancelDisableDialog" max-width="500px">
						<LessonCancelDisableCard @closeAction="closeCancelDisableCard" />
					</v-dialog>
				</template>
				<template v-slot:[`item.detail`]>
					<div>
						<v-btn text x-small>
							<v-icon color="primary">mdi-information-outline</v-icon>
						</v-btn>
					</div>
				</template>
				<template v-slot:[`item.actions`]="{ item }">
					<v-row>
						<!-- （講師）完了報告ボタン -->
						<v-col cols="6">
							<ActiveButton
								:button-title="reportButtonTitle(item)"
								@clickAction="openReportCard(item)"
								class="report-button"
								:is-disabled="isDisableReport(item)"
							/>
						</v-col>
						<!-- レッスンキャンセルボタン -->
						<v-col cols="6">
							<div v-if="canRequestDelete(item.startTime)">
								<!-- 「削除依頼」 レッスン開始後に表示 -->
								<ActiveDangerousButton
									:button-title="$t('top.requestDelete')"
									@clickAction="handleRequestDeleteClick($event, item)"
									class="report-button"
									:isDisabled="item.isRequestedForCancel"
								/>
							</div>
							<div v-else>
								<ActiveButton
									:button-title="$t('top.cancelLesson')"
									@clickAction="openCancelCard(item)"
									class="report-button"
								/>
							</div>
						</v-col>
					</v-row>
				</template>
				<template v-slot:[`item.mail`]="{ item }">
					<!-- スカイプの催促 -->
					<v-icon v-if="showSkypeRemindIcon(item)" @click.stop="openSkypeAddRemindDialog(item)">mdi-skype</v-icon>
				</template>
				<template v-slot:[`item.isFeedback`]="{ item }">
					{{ feedBackText(item) }}
				</template>
			</v-data-table>
		</v-hover>
		<!-- ページネーション -->
		<v-pagination v-model="page" :length="totalPages" class="pagenation" circle @input="fetchLessonData"></v-pagination>
		<v-dialog v-model="showSkypeAddRemindDialog" v-if="showSkypeAddRemindDialog" offset-x max-width="500px">
			<SkypeAddRemindDialog
				@close="closeSkypeAddRemindDialog"
				:studentId="selectedItem.studentId"
				:studentName="selectedItem.studentName"
			/>
		</v-dialog>
	</v-container>
</template>

<script>
import ActiveButton from '../Atoms/ActiveButton'
import ActiveDangerousButton from '../Atoms/ActiveDangerousButton'
import TouchableDisableButton from '../Atoms/TouchableDisableButton'
import LessonReportCard from '../Organisms/LessonReportCard'
import LessonCancelCard from '../Organisms/LessonCancelCard'
import LessonCancelDisableCard from '../Organisms/LessonCancelDisableCard'
import LessonDetailCard from '../Organisms/LessonDetailCard'
import RequestDeleteLessonCard from '../Organisms/RequestDeleteLessonCard'
import SkypeAddRemindDialog from '../Organisms/SkypeAddRemindDialog'
import { mapGetters, mapActions } from 'vuex'
import { CONVERSATION_LESSON_COURSE_ID } from '../../const'

export default {
	name: 'TeacherScheduleTable',
	props: {},
	components: {
		ActiveButton,
		ActiveDangerousButton,
		TouchableDisableButton,
		LessonReportCard,
		LessonCancelCard,
		LessonCancelDisableCard,
		LessonDetailCard,
		RequestDeleteLessonCard,
		SkypeAddRemindDialog
	},
	data: () => ({
		page: 1,
		selected: [],
		plannedLessonArray: [],
		showReportDialog: false,
		showDetailDialog: false,
		showCancelDialog: false,
		showRequestDeleteDialog: false,
		initialReportForm: {
			lessonId: null,
			status: null,
			lessonReport: '',
			lessonFeedbackGood: '',
			lessonFeedbackImprovement: '',
			lessonFeedback: '',
			lessonCourseId: null,
			studentEmail: '',
			studentName: '',
			teacherName: '',
			lessonPlanPdf: '',
			memoForTeacher: '',
			reasonForUnnecessary: '',
			isFeedback: false
		},
		reportForm: {},
		cancelForm: {},
		selectedItem: null,
		showSkypeAddRemindDialog: false,
		showCancelDisableDialog: false
	}),
	watch: {
		plannedTeacherLessons() {
			this.makePlannedLessonArray(this.plannedTeacherLessons)
		}
	},
	created() {
		this.resetReportForm()
	},
	computed: {
		...mapGetters({
			userInfo: 'user/getUserInfo',
			plannedTeacherLessonsLoading: 'plannedTeacherLessons/loading',
			plannedTeacherLessons: 'plannedTeacherLessons/plannedTeacherLessons',
			totalPages: 'plannedTeacherLessons/totalPages',
			totalCount: 'plannedTeacherLessons/totalCount'
		}),
		headers() {
			return [
				{ text: '', value: 'detail' },
				{ text: this.$t('top.startTime'), value: 'startTime' },
				{ text: this.$t('top.endTime'), value: 'endTime' },
				{ text: this.$t('top.course'), value: 'course' },
				{ text: this.$t('top.studentInCharge'), value: 'studentName' },
				{ text: this.$t('top.isFirstLesson'), value: 'strIsFirstLesson' },
				{ text: this.$t('teacherSchedule.feedback'), value: 'isFeedback' },
				{ text: '', value: 'actions', sortable: false },
				{ text: '', value: 'mail', sortable: false }
			]
		},
		// computedに引数を持たせたまま別のcomputedを使うことが困難だったため
		// 冗長な書き方をしている
		reportButtonTitle() {
			// 完了・キャンセルの場合は「報告済み」になる
			return (item) => {
				switch (item.status) {
					case this.$t('top.completed'):
					case this.$t('top.canceledLesson'):
						return this.$t('top.reported')
					default:
						return this.$t('top.report')
				}
			}
		},
		isDisableReport() {
			// 完了・キャンセルの場合はボタンが押せなくなる
			return (item) => {
				// 削除依頼済のものはボタンが押せなくなる
				if (item.isRequestedForCancel) {
					return true
				}

				// 終了時刻 が 現在時刻の後のものだけボタンが押せるようになる
				const now = this.$moment()
				const lessonEndTime = this.$moment(item.endTime)

				if (now.isAfter(lessonEndTime)) {
					return false
				} else {
					return true
				}
			}
		},
		hoverPointer() {
			return 'hoverPointer'
		},
		lessonFeedback() {
			if (this.reportForm.isFeedback) {
				let feedback = ''
				feedback = `▫️いいところ\n${this.reportForm.lessonFeedbackGood}\n\n\n▫️もっとよくなるところ\n${this.reportForm.lessonFeedbackImprovement}`
				return feedback
			} else {
				return ''
			}
		},
		alertChargeText() {
			return `${this.$t('top.student')}： ${this.selectedItem.studentName}`
		},
		itemsPerPage() {
			if (this.isPC) {
				return 10
			} else {
				return 5
			}
		}
	},
	async created() {
		this.fetchPlannedLessonData()
	},
	mounted() {},
	methods: {
		...mapActions({
			fetchPlannedTeacherLessons: 'plannedTeacherLessons/fetchPlannedTeacherLessons'
		}),
		async fetchPlannedLessonData() {
			const payload = {
				accessToken: this.userInfo.accessToken,
				params: {
					teacherId: this.userInfo.teacherId,
					per: this.itemsPerPage,
					page: this.page
				}
			}
			return this.fetchPlannedTeacherLessons(payload)
		},
		makePlannedLessonArray(lessons) {
			this.plannedLessonArray = []
			for (let lesson of lessons) {
				const plannedLesson = {
					lessonId: lesson.id,
					startTime: this.$moment(lesson.startTime).format('YYYY-MM-DD HH:mm'),
					endTime: this.$moment(lesson.endTime).format('YYYY-MM-DD HH:mm'),
					course: this.translateCourseName(lesson.LessonCourse.courseName),
					lessonCourseId: Number(lesson.LessonCourse.id),
					studentId: lesson.Student.id,
					studentName: lesson.Student.name,
					studentEmail: lesson.Student.email,
					studentSex: lesson.Student.sex,
					studentBirthDay: lesson.Student.birthDay,
					studentCountry: lesson.Student.country,
					studentLevel: lesson.Student.level,
					studentCorrectionFrequency: lesson.Student.correctionFrequency,
					studentHopeInLesson: lesson.Student.hopeInLesson,
					studentLearningGoal: lesson.Student.learningGoal,
					studentOtherItem: lesson.Student.otherItem,
					hopeForLesson: lesson.hopeForLesson,
					messageForTeacher: lesson.messageForTeacher,
					isFirstLesson: lesson.isFirstLesson,
					strIsFirstLesson: lesson.isFirstLesson ? this.$t('top.isFirstLesson') : '-',
					teacherId: lesson.Teacher.id,
					teacherName: lesson.Teacher.teacherName,
					skypeId: lesson.Teacher.skypeId,
					isRequestedForCancel: lesson.isRequestedForCancel,
					isFeedback: lesson.isFeedback
				}
				this.plannedLessonArray.push(plannedLesson)
			}
		},
		translateCourseName(value) {
			switch (value) {
				case 'CONVERSATION':
					return this.$t('top.conversationCourse')
				case 'PRIVATE':
					return this.$t('top.privateCourse')
				default:
					return ''
			}
		},
		openReportCard(item) {
			this.showReportDialog = true
			this.resetReportForm()
			this.reportForm.lessonId = item.lessonId
			this.reportForm.studentEmail = item.studentEmail
			this.reportForm.studentName = item.studentName
			this.reportForm.teacherName = item.teacherName
			this.reportForm.lessonCourseId = item.lessonCourseId
			this.reportForm.isFirstLesson = item.isFirstLesson
			this.reportForm.isFeedback = item.isFeedback
			this.selectedItem = item
		},
		// キャンセルできるかどうかを判定してダイアログを出し分ける
		openCancelCard(item) {
			if (this.canCancelLesson(item.startTime)) {
				this.showCancelDialog = true
				this.cancelForm.lessonId = item.lessonId
				this.cancelForm.teacherId = item.teacherId
				this.cancelForm.studentId = item.studentId
				this.cancelForm.lessonCourseId = item.lessonCourseId
				this.cancelForm.cancelComment = ''
				this.cancelForm.cancelBy = 'TEACHER'

				this.selectedItem = item
			} else {
				this.showCancelDisableDialog = true
			}
		},
		handleRequestDeleteClick(event, item) {
			event.stopPropagation() // これがないとレッスン詳細が表示されてしまう
			this.openRequestDeleteCard(item)
		},
		openRequestDeleteCard(item) {
			this.showRequestDeleteDialog = true
			this.showDetailDialog = false // add this line
			this.selectedItem = item
		},
		closeReportCard() {
			this.showReportDialog = false
			this.resetReportForm()
		},
		resetReportForm() {
			this.reportForm = { ...this.initialReportForm }
		},
		closeCancelCard() {
			this.showCancelDialog = false
		},
		closeCancelDisableCard() {
			this.showCancelDisableDialog = false
		},
		closeRequestDeleteLessonCard() {
			this.showRequestDeleteDialog = false
			this.selectedItem = null
		},
		clickRow(row) {
			// レッスン詳細情報ダイアログを表示
			this.showDetailDialog = true
			this.selectedItem = row
		},
		closeDetailCard() {
			this.showDetailDialog = false
		},
		feedBackText(item) {
			if (item.lessonCourseId !== 1) return '-'
			return item.isFeedback ? this.$t('teacherSchedule.required') : this.$t('teacherSchedule.notRequired')
		},
		report() {
			if (confirm(this.$t('top.reportConfirmation'))) {
				// ステータス更新
				switch (this.reportForm.status) {
					case this.$t('top.completed'):
						this.reportForm.status = 1
						break
					case this.$t('top.canceledLesson'):
						this.reportForm.status = 2

						// キャンセルの時はレポート/FB書かない
						this.reportForm.lessonReport = ''
						this.reportForm.lessonFeedback = ''
						break
				}

				// フィードバックを統合する
				this.reportForm.lessonFeedback = this.lessonFeedback

				// レッスン計画書がある場合はフォーマットを変更しなければいけない
				if (this.reportForm.lessonPlanPdf === '') {
					const header = {
						headers: {
							Authorization: `Bearer ${this.userInfo.accessToken}`
						}
					}
					this.axios
						.post(`/api/lessons/report`, this.reportForm, header)
						.then(() => {
							this.$emit('updateDoneLesson')
							this.closeReportCard()
							this.reload()
						})
						.catch((error) => {
							alert(error.response.data.error.message)
						})
				} else {
					let formData = new FormData()

					const obj = this.reportForm
					Object.keys(obj).forEach(function (key) {
						if (obj[key] != null) {
							formData.append(key, obj[key])
						}
					})
					formData.append('lessonPdf', this.reportForm.lessonPlanPdf)

					const header = {
						headers: {
							Authorization: `Bearer ${this.userInfo.accessToken}`,
							'Content-Type': 'multipart/form-data'
						}
					}

					this.axios
						.post(`/api/lessons/report`, formData, header)
						.then(() => {
							this.closeReportCard()
							this.$emit('updateDoneLesson')
							this.reload()
						})
						.catch((error) => {
							alert(error.response.data.error.message)
						})
				}
			}
		},
		cancelLesson() {
			// 注意：以下のレッスンを本当にキャンセルしますか？ レッスン開始時刻: レッスン終了時刻: 生徒/講師
			if (
				confirm(
					`${this.$t('top.cancelLessonAttention')}\n ${this.$t('top.lessonStartTime')}： ${
						this.selectedItem.startTime
					}  \n ${this.$t('top.lessonEndTime')}： ${this.selectedItem.endTime}  \n ${this.alertChargeText}`
				)
			) {
				const header = {
					headers: {
						Authorization: `Bearer ${this.userInfo.accessToken}`
					}
				}

				this.axios
					.post('/api/lessons/cancel', this.cancelForm, header)
					.then(() => {
						this.closeCancelCard()
						this.reload()
					})
					.catch((error) => {
						alert(error.response.data.error.message)
					})
			}
		},
		async reload() {
			const currentPage = this.page
			this.fetchPlannedLessonData()
				.then(() => {
					this.page = currentPage
				})
				.catch((error) => {
					console.error('データ取得中にエラーが発生しました', error)
				})
		},
		openSkypeAddRemindDialog(item) {
			this.selectedItem = item

			// レッスン24時間前以降はリマインドのダイアログを出す
			const now = this.$moment()
			const lessonStartTime = this.$moment(item.startTime)
			const diff = lessonStartTime.diff(now, 'hours')

			if (diff <= 24) {
				this.showSkypeAddRemindDialog = true
			} else {
				// それ以外の場合はポップアップを表示
				alert(this.$t('top.skypeAddRemindAlert'))
			}
		},
		closeSkypeAddRemindDialog() {
			this.selectedItem = null
			this.showSkypeAddRemindDialog = false
		},
		fetchLessonData() {
			this.fetchPlannedLessonData()
		},
		// 授業開始直後からレッスンの削除依頼は行うことができるようになる。
		canRequestDelete(startTime) {
			const now = this.$moment()
			const lessonStartTime = this.$moment(startTime)

			if (now.isAfter(lessonStartTime)) {
				return true
			} else {
				return false
			}
		},
		// 授業開始まで24時間以上ある場合のみキャンセルが可能
		canCancelLesson(startTime) {
			const now = this.$moment()
			const lessonStartTime = this.$moment(startTime)
			const diff = lessonStartTime.diff(now, 'milliseconds')

			return diff >= 24 * 60 * 60 * 1000 // 24時間をミリ秒で表現
		},
		// 会話コース、もしくはプライベートコースの初回の場合にスカイプ催促が行える
		showSkypeRemindIcon(item) {
			return item.isFirstLesson || item.lessonCourseId === CONVERSATION_LESSON_COURSE_ID
		}
	}
}
</script>

<style>
.hoverPointer td {
	cursor: pointer;
}
.pagenation {
	margin: 20px 0 0 0;
}
.v-data-table > .v-data-table__wrapper .v-data-table__mobile-row {
	height: auto;
	min-height: 45px;
}
.v-btn:not(.v-btn--round).v-size--default {
	padding: 0 16px;
}
/* 最終行だけ、ボタンのためheight大きめで */
.theme--light.v-data-table > .v-data-table__wrapper > table > tbody > tr:not(:last-child) > td:last-child,
.theme--light.v-data-table > .v-data-table__wrapper > table > tbody > tr:not(:last-child) > th:last-child {
	height: 50px;
}
</style>
