import axios from 'axios'

export const conversationTickets = {
	namespaced: true,
	state: {
		availableConversationTickets: null,
		expiredConversationTickets: null,
		meta: {
			totalAvailableTicketCount: 0,
			expirationDate: null
		},
		loading: false
	},
	getters: {
		loading: (state) => {
			return state.loading
		},
		availableConversationTickets: (state) => {
			return state.availableConversationTickets
		},
		expiredConversationTickets: (state) => {
			return state.expiredConversationTickets
		},
		totalAvailableTicketCount: (state) => {
			return state.meta.totalAvailableTicketCount
		},
		expirationDate: (state) => {
			return state.meta.expirationDate
		}
	},
	mutations: {
		prepareConnect(state) {
			state.loading = true
		},
		stopLoading(state) {
			state.loading = false
		},
		setConversationTicketsData(state, data) {
			state.availableConversationTickets = data.availableTickets
			state.expiredConversationTickets = data.expiredTickets
		},
		setConversationTicketsMeta(state, meta) {
			state.meta = meta
		}
	},
	actions: {
		async fetchConversationTickets({ commit }, payload) {
			commit('prepareConnect')
			const { accessToken, studentId } = payload

			const header = {
				headers: {
					Authorization: `Bearer ${accessToken}`
				}
			}
			return axios
				.get(`/api/conversation-lesson-tickets/${studentId}`, header)
				.then((res) => {
					commit('setConversationTicketsData', res.data)
					commit('setConversationTicketsMeta', res.data.meta)
				})
				.finally(() => {
					commit('stopLoading')
				})
		},
		async checkAbleToReissue({ commit }, payload) {
			commit('prepareConnect')
			const { accessToken, lessonId } = payload

			const header = {
				headers: {
					Authorization: `Bearer ${accessToken}`
				}
			}
			return axios
				.get(`/api/conversation-lesson-tickets/${lessonId}/check-able-to-reissue`, header)
				.then((res) => {
					return res.data.isAbleToReissue
				})
				.finally(() => {
					commit('stopLoading')
				})
		}
	}
}
