<template>
	<div
		:style="{
			background: '#FAFAFA',
			height: '100%'
		}"
	>
		<v-container>
			<PageTitle title-name="チケット購入履歴" />
			<SwitchTimeZone class="timezone" @reload="reloadTable" />
			<div class="ticketsHistoryArea">
				<v-data-table
					:headers="ticketsHistoryHeaders"
					:items="ticketsHistoryItems"
					:class="{ 'elevation-1': true }"
					hide-default-footer
					disable-sort
					:loading="loading"
					no-data-text="チケット購入履歴はありません。"
					width="20px"
				/>
			</div>
			<VmLink to="/availableConversationTicketsList">所持チケット一覧へ戻る</VmLink>
		</v-container>
	</div>
</template>

<script>
import PageTitle from '@/components/Atoms/PageTitle'
import SwitchTimeZone from '@/components/Atoms/SwitchTimeZone'
import { mapGetters, mapActions } from 'vuex'
import VmLink from '@/components/Atoms/VmLink.vue'

export default {
	name: 'ConversationTicketsHistoryView',
	props: {},
	components: {
		PageTitle,
		SwitchTimeZone,
		VmLink
	},
	data: () => ({}),
	created() {
		this.load()
	},
	mounted() {},
	computed: {
		...mapGetters({
			userInfo: 'user/getUserInfo',
			conversationTicketsLoading: 'conversationTickets/loading',
			availableConversationTickets: 'conversationTickets/availableConversationTickets',
			expiredConversationTickets: 'conversationTickets/expiredConversationTickets'
		}),
		loading() {
			return this.conversationTicketsLoading
		},
		ticketsHistoryHeaders() {
			return [
				{ text: '購入枚数', value: 'purchasedTicketCount', width: '50%' },
				{ text: '追加日', value: 'purchaseDate', width: '30%' },
				{ text: 'フィードバック', value: 'isFeedback', width: '20%' }
			]
		},
		// 購入したすべてのチケットを表示する
		ticketsHistoryItems() {
			const availableTickets = this.availableConversationTickets || []
			const expiredTickets = this.expiredConversationTickets || []

			const combinedTickets = [...availableTickets, ...expiredTickets]

			// purchaseDate で最新順にソート
			const sortedTickets = combinedTickets.sort((a, b) => {
				return new Date(b.purchaseDate) - new Date(a.purchaseDate)
			})

			if (!this.expiredConversationTickets) return []

			return sortedTickets.map((v) => {
				return {
					purchaseDate: this.$moment(v.purchaseDate).format('YYYY/MM/DD HH:mm:ss'),
					purchasedTicketCount: v.purchasedTicketCount,
					isFeedback: v.isFeedback ? 'あり' : 'なし'
				}
			})
		}
	},
	methods: {
		...mapActions({
			fetchConversationTickets: 'conversationTickets/fetchConversationTickets'
		}),
		async load() {
			await this.fetchConversationTickets({
				accessToken: this.userInfo.accessToken,
				studentId: this.userInfo.studentId
			})
		},
		async reloadTable() {
			this.load()
		}
	}
}
</script>

<style lang="scss" scoped>
.timezone {
	margin: 12px 0 30px 0;
}
.subheading {
	margin: 20px 0 20px 0;
}
.ticketsHistoryArea {
	margin-top: 60px;
	margin-bottom: 32px;
}
</style>
