<template>
	<!-- 日時フォーム -->
	<div class="date-form">
		<!-- 開始、終了などのテキスト -->
		<div class="form-label" v-if="hasLabel">
			<p class="form-title-text">{{ formLabelText }}：</p>
		</div>
		<!-- dateピッカー -->
		<div class="picker">
			<DatePicker
				v-model="inputtedDate"
				:showedDate="showedDate"
				:minDate="minDate"
				:maxDate="maxDate"
				@closeDatePicker="closeDatePicker"
				@changeDatePicker="changeDatePicker"
			/>
		</div>
		<!-- timeピッカー -->
		<div class="picker">
			<TimePicker
				v-model="inputtedTime"
				:minuteInterval="minuteInterval"
				:minTime="minTime"
				:maxTime="maxTime"
				:form="timeForm"
				@closeTimePicker="closeTimePicker"
				@changeTimePicker="changeTimePicker"
			/>
		</div>
	</div>
</template>

<script>
import DatePicker from '../Atoms/DatePicker'
import TimePicker from '../Atoms/TimePicker'

export default {
	name: 'DateAndTimeForm',
	props: {
		formLabelText: {
			type: String,
			required: false,
			default: ''
		},
		date: {
			type: String,
			required: false,
			default: ''
		},
		showedDate: {
			type: String,
			required: false,
			default: ''
		},
		minDate: {
			type: String,
			required: false,
			default: ''
		},
		maxDate: {
			type: String,
			required: false,
			default: ''
		},
		time: {
			type: String,
			required: false,
			default: ''
		},
		minuteInterval: {
			type: Number,
			required: false,
			default: 0
		},
		minTime: {
			type: String,
			required: false,
			default: ''
		},
		maxTime: {
			type: String,
			required: false,
			default: ''
		},
		timeForm: {
			type: String,
			required: false,
			default: ''
		},
		hasLabel: {
			type: Boolean,
			required: false,
			default: true
		}
	},
	data: () => ({}),
	watch: {
		maxTime() {
			this.adjustTime('max')
		},
		minTime() {
			this.adjustTime('min')
		}
	},
	computed: {
		inputtedDate: {
			get() {
				return this.date
			},
			set(value) {
				this.$emit('update:date', value)
			}
		},
		inputtedTime: {
			get() {
				return this.time
			},
			set(value) {
				this.$emit('update:time', value)
			}
		}
	},
	methods: {
		closeDatePicker(val) {
			this.$emit('closeDatePicker', val)
		},
		closeTimePicker(val) {
			this.$emit('closeTimePicker', val)
		},
		changeDatePicker() {
			this.$emit('changeDatePicker')
		},
		changeTimePicker() {
			this.$emit('changeTimePicker')
		},
		adjustTime(type) {
			const time = this.$moment(`${this.inputtedDate} ${this[type + 'Time']}`, 'YYYY-MM-DD HH:mm')
			const selectedTime = this.$moment(`${this.inputtedDate} ${this.inputtedTime}`, 'YYYY-MM-DD HH:mm')

			if ((type === 'max' && selectedTime.isAfter(time)) || (type === 'min' && selectedTime.isBefore(time))) {
				this.inputtedTime = this[type + 'Time']
			}
		}
	},
	components: {
		DatePicker,
		TimePicker
	}
}
</script>

<style lang="scss" scoped>
.date-form {
	display: flex;
	flex-wrap: wrap;
	margin-top: 10px;
	margin-bottom: 10px;
}

.form-label {
	flex: 1 1 100%;
}

.picker {
	flex: 1 1 100%;
	margin-bottom: 10px;
}

@media (min-width: 768px) {
	.picker {
		flex: 1 1 45%;
		margin-right: 10px;
	}
	.picker:last-child {
		margin-right: 0;
	}
}
</style>
