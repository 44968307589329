<template>
	<div>
		<v-hover disabled>
			<v-data-table
				:headers="headers"
				:items="plannedLessonArray"
				:page.sync="page"
				:items-per-page="itemsPerPage"
				@page-count="pageCount = $event"
				:class="{ 'elevation-1': true }"
				hide-default-footer
				disable-sort
				:no-data-text="$t('top.noLesson')"
				:loading="plannedStudentLessonsLoading"
			>
				<template v-slot:top class="not-yubi">
					<!-- レッスンキャンセルダイアログ -->
					<v-dialog v-model="showCancelDialog" v-if="showCancelDialog" max-width="500px">
						<LessonCancelCard
							@closeAction="closeCancelCard"
							@cancelAction="cancelLesson"
							:formTitle="$t('top.cancelLessonTitle')"
							:lessonInfo="selectedItem"
							:cancelForm="cancelForm"
							:isTeacher="false"
						/>
					</v-dialog>
					<!-- 「この授業は削除できません」のダイアログ -->
					<v-dialog v-model="showCancelDisableDialog" v-if="showCancelDisableDialog" max-width="500px">
						<CancelDisableCard @closeAction="closeDisableDialog" />
					</v-dialog>
				</template>
				<template v-slot:[`item.actions`]="{ item }">
					<v-row>
						<!-- レッスンキャンセルボタン -->
						<v-col cols="6">
							<div v-if="isDisableCancel(item)">
								<TouchableDisableButton
									:button-title="$t('top.cancelLesson')"
									@clickAction="openDisableDialog"
									class="report-button"
								/>
							</div>
							<div v-else>
								<ActiveButton
									:button-title="$t('top.cancelLesson')"
									@clickAction="openCancelCard(item)"
									class="report-button"
								/>
							</div>
						</v-col>
					</v-row>
				</template>
			</v-data-table>
		</v-hover>
		<v-pagination v-model="page" :length="totalPages" class="pagenation" circle @input="fetchLessonData"></v-pagination>
	</div>
</template>

<script>
import ActiveButton from '../Atoms/ActiveButton'
import TouchableDisableButton from '../Atoms/TouchableDisableButton'
import LessonCancelCard from '../Organisms/LessonCancelCard'
import CancelDisableCard from '../Molecules/CancelDisableCard'
import { mapGetters, mapActions } from 'vuex'

export default {
	name: 'StudentScheduleTable',
	inject: ['reloadRouter', 'reloadHeader'],
	props: {
		lessons: {
			type: Array,
			default: () => []
		},
		defaultPage: {
			type: Number,
			required: false
		}
	},
	components: {
		ActiveButton,
		TouchableDisableButton,
		LessonCancelCard,
		CancelDisableCard
	},
	data: () => ({
		page: 1,
		plannedLessonArray: [],
		showCancelDialog: false,
		showCancelDisableDialog: false,
		cancelForm: {},
		selectedItem: null
	}),
	watch: {
		defaultPage() {
			if (this.defaultPage) this.page = this.defaultPage
		},
		plannedStudentLessons() {
			this.makePlannedLessonArray(this.plannedStudentLessons)
		}
	},
	computed: {
		...mapGetters({
			userInfo: 'user/getUserInfo',
			plannedStudentLessonsLoading: 'plannedStudentLessons/loading',
			plannedStudentLessons: 'plannedStudentLessons/plannedStudentLessons',
			totalPages: 'plannedStudentLessons/totalPages',
			totalCount: 'plannedStudentLessons/totalCount'
		}),
		headers() {
			return [
				{ text: this.$t('top.startTime'), value: 'startTime' },
				{ text: this.$t('top.endTime'), value: 'endTime' },
				{ text: this.$t('top.course'), value: 'course' },
				{ text: this.$t('top.teacherName'), value: 'teacherName' },
				{ text: this.$t('top.skypeId'), value: 'skypeId' },
				{ text: '', value: 'actions', sortable: false }
			]
		},
		isDisableReport() {
			// 完了・キャンセルの場合はボタンが押せなくなる
			return (item) => {
				// 削除依頼済のものはボタンが押せなくなる
				if (item.isRequestedForCancel) {
					return true
				}

				// 終了時刻 が 現在時刻の後のものだけボタンが押せるようになる
				const now = this.$moment()
				const lessonEndTime = this.$moment(item.endTime)

				if (now.isAfter(lessonEndTime)) {
					return false
				} else {
					return true
				}
			}
		},
		// 授業開始まで48時間を切るとキャンセルできなくなる
		isDisableCancel() {
			return (item) => {
				const startTime = this.$moment(item.startTime)
				const twoDaysAfterDay = this.$moment().add(2, 'days')

				// 生徒は2日前までならキャンセルできる
				// レッスンの開始時刻 < 2日後
				if (twoDaysAfterDay.isAfter(startTime)) {
					return true
				} else {
					return false
				}
			}
		},
		alertChargeText() {
			return `講師名： ${this.selectedItem.teacherName}先生`
		},
		itemsPerPage() {
			if (this.isPC) {
				return 10
			} else {
				return 5
			}
		}
	},
	created() {
		this.fetchPlannedLessonData()
	},
	mounted() {},
	methods: {
		...mapActions({
			fetchPlannedStudentLessons: 'plannedStudentLessons/fetchPlannedStudentLessons'
		}),
		async fetchPlannedLessonData() {
			const payload = {
				accessToken: this.userInfo.accessToken,
				params: {
					studentId: this.userInfo.studentId,
					per: this.itemsPerPage,
					page: this.page
				}
			}
			return this.fetchPlannedStudentLessons(payload)
		},
		makePlannedLessonArray(lessons) {
			this.plannedLessonArray = []
			for (let lesson of lessons) {
				const plannedLesson = {
					lessonId: lesson.id,
					startTime: this.$moment(lesson.startTime).format('YYYY-MM-DD HH:mm'),
					endTime: this.$moment(lesson.endTime).format('YYYY-MM-DD HH:mm'),
					course: lesson.LessonCourse ? this.translateCourseName(lesson.LessonCourse.courseName) : '',
					lessonCourseId: lesson.LessonCourse ? Number(lesson.LessonCourse.id) : null,
					teacherId: lesson.Teacher ? lesson.Teacher.id : null,
					studentId: lesson.Student ? lesson.Student.id : null,
					teacherName: lesson.Teacher ? lesson.Teacher.teacherName : '',
					skypeId: lesson.Teacher ? lesson.Teacher.skypeId : '',
					isRequestedForCancel: lesson.isRequestedForCancel
				}
				this.plannedLessonArray.push(plannedLesson)
			}
		},
		translateCourseName(value) {
			switch (value) {
				case 'CONVERSATION':
					return this.$t('top.conversationCourse')
				case 'PRIVATE':
					return this.$t('top.privateCourse')
				default:
					return ''
			}
		},
		openCancelCard(item) {
			this.showCancelDialog = true
			this.cancelForm.lessonId = item.lessonId
			this.cancelForm.teacherId = item.teacherId
			this.cancelForm.studentId = item.studentId
			this.cancelForm.lessonCourseId = item.lessonCourseId
			this.cancelForm.cancelComment = ''
			this.cancelForm.cancelBy = 'STUDENT'

			this.selectedItem = item
		},
		openDisableDialog() {
			this.showCancelDisableDialog = true
		},
		closeCancelCard() {
			this.showCancelDialog = false
		},
		closeDisableDialog() {
			this.showCancelDisableDialog = false
		},
		async cancelLesson() {
			// 注意：以下のレッスンを本当にキャンセルしますか？ レッスン開始時刻: レッスン終了時刻: 生徒/講師
			if (
				confirm(
					`${this.$t('top.cancelLessonAttention')}\n${this.$t('top.lessonStartTime')}： ${
						this.selectedItem.startTime
					}  \n ${this.$t('top.lessonEndTime')}： ${this.selectedItem.endTime}  \n ${this.alertChargeText}`
				)
			) {
				const header = {
					headers: {
						Authorization: `Bearer ${this.userInfo.accessToken}`
					}
				}

				this.axios
					.post('/api/lessons/cancel', this.cancelForm, header)
					.then((response) => {
						this.$store.commit('user/SET_USER_TICKETNUM', response.data.student.numberOfTicket)
						this.closeCancelCard()
						this.reloadRouter()
						this.reloadHeader()
					})
					.catch((error) => {
						alert(error.response.data.error.message)
					})
			}
		},
		reload() {
			this.$emit('reload', this.page)
		},
		fetchLessonData() {
			this.fetchPlannedLessonData()
		}
	}
}
</script>

<style>
.hoverPointer td {
	cursor: pointer;
}
.pagenation {
	margin: 20px 0 0 0;
}
.v-data-table > .v-data-table__wrapper .v-data-table__mobile-row {
	height: auto;
	min-height: 45px;
}
.v-btn:not(.v-btn--round).v-size--default {
	padding: 0 16px;
}
/* 最終行だけ、ボタンのためheight大きめで */
.theme--light.v-data-table > .v-data-table__wrapper > table > tbody > tr:not(:last-child) > td:last-child,
.theme--light.v-data-table > .v-data-table__wrapper > table > tbody > tr:not(:last-child) > th:last-child {
	height: 50px;
}
</style>
