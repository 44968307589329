var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{style:({
		background: '#FAFAFA',
		height: '100%'
	})},[_c('v-container',[_c('PageTitle',{attrs:{"title-name":"会話コース所持チケット一覧"}}),_c('SwitchTimeZone',{staticClass:"timezone",on:{"reload":_vm.reloadTable}}),_c('div',{staticClass:"availableTicketsArea"},[_c('div',{staticClass:"totalAvailableTickets"},[_vm._v(" 有効な会話コースチケットの合計: "),_c('span',{staticClass:"totalAvailableTickets__num"},[_vm._v(_vm._s(_vm.totalAvailableTicketCount))]),_vm._v(" 枚 ")]),_c('v-data-table',{class:{ 'elevation-1': true },attrs:{"headers":_vm.availableTicketsHeaders,"items":_vm.availableTicketsItems,"hide-default-footer":"","disable-sort":"","loading":_vm.loading,"no-data-text":"使用可能なチケットがありません。"},scopedSlots:_vm._u([{key:"item.currentTicketCount",fn:function(ref){
	var item = ref.item;
return [_c('strong',[_vm._v(_vm._s(item.currentTicketCount))])]}},{key:"item.isFeedback",fn:function(ref){
	var item = ref.item;
return [_c('strong',[_vm._v(_vm._s(item.isFeedback))])]}},{key:"item.expirationDate",fn:function(ref){
	var item = ref.item;
return [_c('strong',[_vm._v(_vm._s(item.expirationDate))])]}}])})],1),_c('ActiveButtonWithArrow',{attrs:{"button-title":"会話チケットを追加購入する"},on:{"clickAction":_vm.toBuyTicket}}),_c('VmLink',{staticClass:"toTicketHistory",attrs:{"to":"/conversationTicketsHistory"}},[_vm._v("チケット購入履歴はこちら")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }