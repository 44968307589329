<template>
	<div
		:style="{
			background: '#FAFAFA',
			height: '100%'
		}"
	>
		<v-container>
			<PageTitle title-name="会話コース所持チケット一覧" />
			<SwitchTimeZone class="timezone" @reload="reloadTable" />
			<div class="availableTicketsArea">
				<div class="totalAvailableTickets">
					有効な会話コースチケットの合計:
					<span class="totalAvailableTickets__num">{{ totalAvailableTicketCount }}</span> 枚
				</div>
				<v-data-table
					:headers="availableTicketsHeaders"
					:items="availableTicketsItems"
					:class="{ 'elevation-1': true }"
					hide-default-footer
					disable-sort
					:loading="loading"
					no-data-text="使用可能なチケットがありません。"
				>
					<template v-slot:item.currentTicketCount="{ item }">
						<strong>{{ item.currentTicketCount }}</strong>
					</template>
					<template v-slot:item.isFeedback="{ item }">
						<strong>{{ item.isFeedback }}</strong>
					</template>
					<template v-slot:item.expirationDate="{ item }">
						<strong>{{ item.expirationDate }}</strong>
					</template>
				</v-data-table>
			</div>
			<ActiveButtonWithArrow button-title="会話チケットを追加購入する" @clickAction="toBuyTicket" />
			<VmLink to="/conversationTicketsHistory" class="toTicketHistory">チケット購入履歴はこちら</VmLink>
		</v-container>
	</div>
</template>

<script>
import PageTitle from '@/components/Atoms/PageTitle'
import SwitchTimeZone from '@/components/Atoms/SwitchTimeZone'
import ActiveButtonWithArrow from '@/components/Atoms/ActiveButtonWithArrow'
import { mapGetters, mapActions } from 'vuex'
import VmLink from '@/components/Atoms/VmLink.vue'

export default {
	name: 'AvailableConversationTicketsListView',
	props: {},
	components: {
		PageTitle,
		SwitchTimeZone,
		ActiveButtonWithArrow,
		VmLink
	},
	data: () => ({}),
	created() {
		this.load()
	},
	mounted() {},
	computed: {
		...mapGetters({
			userInfo: 'user/getUserInfo',
			conversationTicketsLoading: 'conversationTickets/loading',
			availableConversationTickets: 'conversationTickets/availableConversationTickets',
			totalAvailableTicketCount: 'conversationTickets/totalAvailableTicketCount'
		}),
		loading() {
			return this.conversationTicketsLoading
		},
		availableTicketsHeaders() {
			return [
				{ text: '所持枚数', value: 'currentTicketCount', width: '30%' },
				{ text: 'フィードバック', value: 'isFeedback', width: '20%' },
				{ text: '使用期限', value: 'expirationDate', width: '50%' }
			]
		},
		availableTicketsItems() {
			if (!this.availableConversationTickets) return []
			return this.availableConversationTickets.map((v) => {
				return {
					purchaseDate: this.$moment(v.purchaseDate).format('YYYY/MM/DD HH:mm:ss'),
					purchasedTicketCount: v.purchasedTicketCount,
					isFeedback: v.isFeedback ? 'あり' : 'なし',
					currentTicketCount: v.currentTicketCount,
					expirationDate: this.$moment(v.expirationDate).format('YYYY/MM/DD HH:mm:ss')
				}
			})
		}
	},
	methods: {
		...mapActions({
			fetchConversationTickets: 'conversationTickets/fetchConversationTickets'
		}),
		async load() {
			await this.fetchConversationTickets({
				accessToken: this.userInfo.accessToken,
				studentId: this.userInfo.studentId
			})
		},
		async reloadTable() {
			this.load()
		},
		toBuyTicket() {
			this.$router.push({ path: 'buyTicketTop' })
		}
	}
}
</script>

<style lang="scss" scoped>
.timezone {
	margin: 12px 0 30px 0;
}
.subheading {
	margin: 20px 0 20px 0;
}
.totalAvailableTickets {
	margin: 0 0 20px 0;
	&__num {
		font-size: 1.2em;
		color: #dcc268;
		font-weight: bold;
	}
}
.availableTicketsArea {
	margin-top: 20px;
	margin-bottom: 32px;
}
.toTicketHistory {
	margin-top: 20px;
}
</style>
