<template>
	<!-- TODO: stripeがokになるまであげない -->
	<!-- <VmDialogCard
    title="チケット申込"
    buttonTitle="チケットを購入する"
    @click="buyTicket"
    @close="close"
    :disabled="!buttonValidation"
  > -->
	<!-- TODO: stripeがokになるまでこちらをprodにあげる -->
	<VmDialogCard
		title="チケット申込"
		buttonTitle="チケット申込みをする"
		@click="apply"
		@close="close"
		:disabled="!buttonValidation"
	>
		<template #cardContent>
			<div>
				<p class="label-text">
					確認事項
					<br />
					<span class="explanation-text">
						講師の空き予定には限りがあります。<br />
						事前に
						<a href="https://vollmond-membership.com/ConversationTeacherList" target="_blank" rel="noopener"
							>こちらの講師一覧ページ
						</a>
						より予定が合いそうな講師がいることを確認してからチケットを購入することをおすすめします。
					</span>
				</p>
				<v-checkbox v-model="checkedConfirmation" label="確認しました" hide-details> </v-checkbox>
			</div>
			<div>
				<p class="label-text">チケットの種類を選択してください</p>
				<v-select
					:items="ticketTypeItems"
					v-model="selectedTicketType"
					label="チケットの種類"
					item-value="value"
					item-text="text"
					solo
					hide-details
				>
					<template v-slot:item="{ item }">
						<div class="ticketType__row">
							{{ item.text }}
							<span v-if="item.value" class="recommended-badge"> おすすめ！ </span>
						</div>
					</template>
				</v-select>
				<div class="feedback-explanation">
					<div class="feedback-explanation__title">
						<p>フィードバックについて</p>
					</div>
					<p>
						講師からフィードバック（いいところ・よくなるところ）がレッスン後メールで届きます。講師によってはフィードバックがドイツ語です。
					</p>
					<div class="feedback__example">
						<VmAccordionMenu text="フィードバック例はこちら" @click="showFeedbackExample = !showFeedbackExample" />
					</div>
					<div class="feedback__example" v-if="showFeedbackExample">
						<div class="feedback-example">
							<span :style="{ 'font-weight': 'bold' }">いいところ</span> <br />
							{{ $t('top.goodPointPlaceHolder') }}
							<br />
							<br />
							<span :style="{ 'font-weight': 'bold' }">もっとよくなるところ</span>
							<br />
							{{ $t('top.getBetterPointPlaceHolder') }}
						</div>
					</div>
				</div>
			</div>
			<div>
				<p class="label-text">チケット枚数（1枚＝20分レッスン1回）</p>
				<span class="ticketNum__attention">5枚以上購入で5%OFF、10枚以上購入で10%OFF!!</span>
				<div class="ticketNum__select">
					<div class="ticketNum__select__content">
						<v-select
							:items="ticketNumItems"
							v-model="selectedTicket"
							item-value="num"
							item-text="displayText"
							label="チケット枚数を選択してください"
							solo
							hide-details
						>
							<template v-slot:item="{ item }">
								<div class="ticketNum__row">
									{{ item.num }}枚
									<span v-if="item.discount > 0" class="discount-badge"> - {{ item.discount }}% </span>
									<br v-if="!isPC" />
									<span class="original-price" v-if="item.discount > 0">
										<s>{{ item.originalPrice.toLocaleString() }}円</s>
									</span>
									<span class="price">{{ item.price ? `→${item.price.toLocaleString()}円` : '' }}</span>
									<br v-if="!isPC" />
									<span v-if="item.num === 10" class="recommended-badge"> おすすめ！ </span>
								</div>
							</template>
						</v-select>
						<v-text-field
							label="チケット枚数を入力（半角数字）"
							v-model="inputtedNum"
							solo
							hide-details
							v-if="selectedTicket === 'その他（手動入力）'"
							type="number"
							:style="{ 'margin-top': '10px' }"
							:min="1"
						></v-text-field>
						<v-row>
							<v-col cols="10">
								合計金額:
								<span v-if="selectedTicketDiscount" class="original-price">
									<s>{{ computedOriginalTotalAmount.toLocaleString() }}円</s>
								</span>
								{{ computedTotalAmount.toLocaleString() }}円
								<span v-if="selectedTicketDiscount" class="discount-text">
									({{ getDiscountForNum(ticketNum) }}% OFF)
								</span>
							</v-col>
						</v-row>
					</div>
					<p
						class="unit-text ticketNum__select__unit"
						:style="selectedTicket === 'その他（手動入力）' ? { 'margin-top': '80px' } : { 'margin-top': '25px' }"
					>
						枚
					</p>
				</div>
			</div>
			<!-- TODO: stripeがokになるまであげない -->
			<!-- <div>
        <v-row>
          <v-col cols="10">
            <p class="label-text">
              支払い方法
              <br />
              <span class="explanation-text">
                クレジットカードの場合は外部サイトにて決済後、チケットが追加されます。
                銀行振込、Paypalの場合は、vollmond事務局が入金を確認した後チケットを追加致します。
              </span>
            </p>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="10">
            <v-select
              :items="paymentItems"
              v-model="selectedPayment"
              label="選択してください"
              solo
              hide-details
            ></v-select>
          </v-col>
        </v-row>
      </div> -->
			<!-- TODO: stripeがokになるまであげないのはここまで -->
		</template>
	</VmDialogCard>
</template>

<script>
import VmDialogCard from '../Molecules/VmDialogCard'
import VmAccordionMenu from '../Atoms/VmAccordionMenu'
import { availableTicketDate, ticketValidatedDateEnd } from '../../util/availableTicketDate'
import {
	CONVERSATION_TICKET_PRICE,
	CONVERSATION_TICKET_PRICE_DISCOUNT_THRESHOLD_5,
	CONVERSATION_TICKET_PRICE_DISCOUNT_THRESHOLD_10,
	CONVERSATION_FEEDBACK_TICKET_PRICE
} from '../../const'

export default {
	name: 'BuyTicketFormCard',
	inject: ['reloadHeader'],
	components: {
		VmDialogCard,
		VmAccordionMenu
	},
	data: () => ({
		selectedTicket: null,
		inputtedNum: null,
		selectedTicketType: null,
		paymentItems: ['クレジットカード', '振込/Paypal'],
		selectedPayment: '',
		showFeedbackExample: false,
		checkedConfirmation: false
	}),
	props: ['userInfo'],
	computed: {
		ticketTypeItems() {
			return [
				{ text: `フィードバック付き ${CONVERSATION_FEEDBACK_TICKET_PRICE}円`, value: true },
				{ text: `フィードバックなし ${CONVERSATION_TICKET_PRICE}円`, value: false }
			]
		},
		// フィードバック付きかなしかでチケット価格を変える
		ticketPrice() {
			return this.selectedTicketType ? CONVERSATION_FEEDBACK_TICKET_PRICE : CONVERSATION_TICKET_PRICE
		},
		// ディスカウントされているかどうか
		selectedTicketDiscount() {
			return this.ticketNum >= CONVERSATION_TICKET_PRICE_DISCOUNT_THRESHOLD_5
		},
		// チケット枚数だけの割引込みの小計
		computedTotalAmount() {
			let discount = 0

			discount = this.getDiscountForNum(this.ticketNum)
			let amount = this.calculateDiscountedPrice(this.ticketNum, discount)
			return amount
		},
		// チケット枚数だけの割引なしの小計
		computedOriginalTotalAmount() {
			let amount = this.ticketNum * this.ticketPrice
			return amount
		},
		ticketNumItems() {
			return [
				{ num: 5, discount: 5 },
				{ num: 10, discount: 10 },
				{ num: 15, discount: 10 },
				{ num: 'その他（手動入力）', discount: 0 }
			].map((item) => {
				const originalPrice = item.num * this.ticketPrice
				const price = this.calculateDiscountedPrice(item.num, item.discount)
				const displayText = typeof item.num === 'number' ? `${item.num}枚` : 'その他（手動入力）'
				return {
					...item,
					originalPrice,
					price,
					displayText: displayText
				}
			})
		},
		buttonValidation() {
			if (this.selectedTicketType === null) return false // 選択されていない状態はnull
			if (!this.selectedTicket) return false
			if (!this.checkedConfirmation) return false
			// TODO: stripeがokになるまであげない
			// if (!this.selectedPayment) {
			//   return false;
			// }

			if (this.selectedTicket === 'その他（手動入力）') {
				if (!this.inputtedNum) return false
			}
			return true
		},
		ticketNum() {
			if (this.selectedTicket != 'その他（手動入力）') {
				return this.selectedTicket
			} else {
				return this.inputtedNum
			}
		},
		ticketDate() {
			return `${availableTicketDate()}分チケット\n(有効期限: ${ticketValidatedDateEnd()})`
		}
	},
	mounted() {
		// 画面表示時にチケット枚数を取得して更新する
	},
	methods: {
		buyTicket() {
			// 選択した支払い方法によってボタンの挙動を変更する
			if (this.selectedPayment === 'クレジットカード') {
				this.toPaymentLink()
			} else {
				this.apply()
			}
		},
		// Stripeの購入画面へ
		toPaymentLink() {
			this.$store.commit('loading/SET_LOADING', true)
			const header = {
				headers: {
					Authorization: `Bearer ${this.userInfo.accessToken}`
				}
			}

			this.axios
				.get(
					`/api/stripes/ticket/paymentlink?numOfTicket=${this.ticketNum}&isFeedBack=${this.selectedTicketType}&studentId=${this.userInfo.studentId}`,
					header
				)
				.then((response) => {
					window.open(response.data.paymentLink)
					this.$store.commit('loading/SET_LOADING', false)
					this.close(false)
				})
				.catch((error) => {
					alert(error.response.data.error.message)
				})
		},
		apply() {
			if (
				confirm(
					`チケット購入の申請を行います。\n\n＜購入情報＞\nチケット枚数: ${this.ticketNum}\nフィードバック: ${
						this.selectedTicketType ? 'あり' : 'なし'
					}`
				)
			) {
				this.$store.commit('loading/SET_LOADING', true)

				const header = {
					headers: {
						Authorization: `Bearer ${this.userInfo.accessToken}`
					}
				}

				const ticketApplyData = {
					studentId: this.userInfo.studentId,
					numberOfTicket: this.ticketNum,
					isFeedback: this.selectedTicketType,
					amount: this.computedTotalAmount
				}
				this.axios
					.post('/api/tickets/apply', ticketApplyData, header)
					.then((response) => {
						console.log('response.data', response.data)
						this.$store.commit('loading/SET_LOADING', false)
						// 「申込ありがとうございました」のメッセージを出しつつ、フォームを閉じる
						this.close(true)
					})
					.catch((error) => {
						this.$store.commit('loading/SET_LOADING', false)
						alert(error.response.data.error.message)
					})
			}
		},
		close(isApplied) {
			this.$emit('cancelAction', isApplied)
		},
		calculateDiscountedPrice(num, discount) {
			return Math.floor((num * this.ticketPrice * (100 - discount)) / 100)
		},
		getDiscountForNum(num) {
			if (num >= CONVERSATION_TICKET_PRICE_DISCOUNT_THRESHOLD_10) {
				return 10
			} else if (num >= CONVERSATION_TICKET_PRICE_DISCOUNT_THRESHOLD_5) {
				return 5
			} else {
				return 0
			}
		}
	},
	head: {}
}
</script>

<style lang="scss" scoped>
.ticketDate {
	font-weight: bold;
	&__label {
		color: #6881dc;
		white-space: pre-wrap;
	}
}
.label-text {
	font-size: 1.3em;
	font-weight: bold;
	margin: 0;
	margin: 20px 0 8px 0;
}
.ticketNum {
	&__attention {
		color: red;
		font-weight: bold;
	}
	&__row {
		@media screen and (max-width: 600px) {
			margin-top: 24px;
		}
	}
	&__select {
		display: flex;
		margin-top: 12px;
		&__content {
			flex-grow: 2;
		}
		&__unit {
			margin-left: 12px;
		}
	}
}
.feedback {
	&__example {
		margin-top: 4px;
	}
}
.feedback-explanation {
	p {
		margin: 0;
	}
	&__title {
		font-size: 1.2em;
		font-weight: bold;
		display: inline-block;
		p {
			margin-bottom: 8px;
		}
	}
	margin: 10px 0 0 0;
	font-weight: normal;
	font-size: 0.9em;
	background-color: #f0f0f0;
	padding: 1em 1em;
	border-radius: 8px;
}
.explanation-text {
	margin: 10px 0 0 0;
	font-weight: normal;
	font-size: 0.7em;
}
.feedback-example {
	position: relative;
	margin: 10px 0;
	padding: 1em 1em;
	border-radius: 8px;
	background-color: #ffffff;
}
.discount-badge {
	color: white;
	font-size: 0.8em;
	background-color: red;
	padding: 5px 4px;
	margin-right: 4px;
	border-radius: 3px;
	font-weight: bold;
}
.recommended-badge {
	color: white;
	font-size: 0.8em;
	background-color: #dcc268;
	padding: 5px 4px;
	margin-left: 10px;
	border-radius: 3px;
	font-weight: bold;
	@media screen and (max-width: 600px) {
		margin-left: 0;
	}
}
.discount-text {
	color: red;
	font-weight: bold;
}
.original-price {
	color: #888;
	font-weight: 0.5em;
}
.price {
	font-weight: bold;
}
</style>
