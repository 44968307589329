<template>
	<div :style="{ background: '#FAFAFA', minHeight: '80vh' }">
		<v-container>
			<PageTitle titleName="プライベートコース受講料" />
			<p class="notification">
				プライベートコースは月毎の「後払い」です。今月分のレッスン代につきましては、来月5日までに請求メールを送信いたしますのでそのメールをお待ちください（件名「受講料ご入金のお願い」）。メールが届いていない場合は
				<a href="https://vollmond.online/kontaktieren/" target="_blank" rel="noopener"> 事務局までご連絡 </a>
				ください。<br />
				講師の受講料変更のタイミングが重なる場合など、稀に表示料金と請求料金が異なるケースがあります。予めご了承ください。
				<br />
				その他お支払い情報に関するお問い合わせは
				<a href="https://vollmond.online/kontaktieren/" target="_blank" rel="noopener">事務局までご連絡 </a>
				ください。<br />
				支払い方法にはクレジットカード・PayPal・Wise・銀行振込が使用できます。
			</p>
			<VmAccordionMenu text="ご入金方法" @click="showHowToPay = !showHowToPay" />
			<div :class="$style.howToPayText" v-if="showHowToPay">
				<p>
					以下、ご都合のいい方法でお振り込みください。<br />
					（いずれも可能な場合には、銀行振込ですと大変助かります）
				</p>
				<p>
					<b>＜クレジットカード＞</b><br />
					<a href="https://buy.stripe.com/00gg2g5HLeFw2I000g" target="_blank" rel="noopener"
						>https://buy.stripe.com/00gg2g5HLeFw2I000g</a
					>
				</p>
				<p>
					<b>＜PayPal＞</b><br />
					<a href="https://vollmond.online/lektion/paypal/" target="_blank" rel="noopener"
						>https://vollmond.online/lektion/paypal/</a
					>
				</p>
				<p>
					<b>＜Wise＞</b><br />
					宛先アドレス: lesson@vollmond.online
				</p>
				<p>
					<b>＜銀行振込＞</b><br />
					——————————————<br />
					金融機関: 住信SBIネット銀行<br />
					支店名: 法人第一支店(106)<br />
					口座番号: (普)1747738<br />
					口座名義: フオルモント（カ<br />
					——————————————
				</p>
				<p>
					<b>ご入金時の注意</b><br />
					※受講生とお振込(カード)名義が一致しない場合は、事前に<br />
					【①お振込日・②お振込金額・③お振込(カード)名義】<br />
					をお知らせください。確認ができない場合、<br />
					再度振込依頼のご連絡をすることがございますので、<br />
					予めご了承ください。<br /><br />
					※入金金額に過不足があった場合<br />
					返金対応および不足分の再請求をさせていただきます。なお、<br />
					その際の振込手数料はお客様ご負担となります。<br /><br />
					※返金が必要な場合<br />
					入金超過分から振込手数料を差し引いて銀行振込にて返金いたします。<br />
					差し引きにより返金金額が発生しない場合は返金できかねますので、<br />
					予めご了承ください。<br /><br />
					※Wise/銀行振込の場合<br />
					恐れ入りますがお振込手数料のご負担をお願いいたします。<br /><br />
					※口座情報の入力間違いにご注意ください。<br />
					入力情報の相違等によって入金不能があった場合、<br />
					ご利用の金融機関で発生する手数料は全てお客様のご負担となります。<br />
					弊社で負担することはできかねますので、予めご了承ください。
				</p>
			</div>
			<AboutLessonStatus class="aboutLessonStatus" background-color="#ffffff" />
			<SelectMonth v-model="selectedMonth" @fetchData="setUpData" :disabled="isDisabledMonthSelect">
				<template #additionalContent> 合計金額: {{ totalInvoiceForStudent }} 円 </template>
			</SelectMonth>
			<v-data-table
				:headers="headers"
				:items="invoiceItems"
				:loading="studentInvoiceLoading"
				disable-sort
				no-data-text="受講したレッスンはありません"
				:items-per-page="-1"
				hide-default-footer
			>
			</v-data-table>
		</v-container>
	</div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import PageTitle from '../Atoms/PageTitle'
import SelectMonth from '../Molecules/SelectMonth'
import AboutLessonStatus from '../Atoms/AboutLessonStatus'
import VmAccordionMenu from '../Atoms/VmAccordionMenu'

export default {
	name: 'StudentInvoice',
	props: {},
	components: {
		PageTitle,
		SelectMonth,
		AboutLessonStatus,
		VmAccordionMenu
	},
	watch: {
		selectedMonth: function (newVal, oldVal) {
			this.setUpData()
		}
	},
	data: () => ({
		selectedMonth: null,
		isDisabledMonthSelect: false,
		showHowToPay: false
	}),
	created() {},
	mounted() {},
	computed: {
		...mapGetters({
			userInfo: 'user/getUserInfo',
			studentInvoices: 'studentInvoices/studentInvoices',
			studentInvoiceLoading: 'studentInvoices/loading'
		}),
		headers() {
			return [
				{
					text: '開始時刻',
					align: 'start',
					value: 'startTime'
				},
				{
					text: '終了時刻',
					value: 'endTime'
				},
				{
					text: '担当講師',
					value: 'teacherName'
				},
				{
					text: 'ステータス',
					value: 'status'
				},
				{
					text: '',
					value: 'isFirstLesson'
				},
				{
					text: '金額',
					value: 'invoiceForStudent'
				}
			]
		},
		// テーブルに表示するデータを整形する
		invoiceItems() {
			if (!this.studentInvoices) return []
			return this.studentInvoices.map((invoice) => {
				// 数値をコンマ区切りの文字列に変換
				const formattedInvoiceForStudent = Number(invoice.invoiceForStudent).toLocaleString('ja-JP')

				return {
					startTime: this.$moment(invoice.startTime).tz('Asia/Tokyo').format('YYYY/MM/DD HH:mm'),
					endTime: this.$moment(invoice.endTime).tz('Asia/Tokyo').format('YYYY/MM/DD HH:mm'),
					teacherName: invoice.Teacher.teacherName,
					status: this.$t(`labels.lessonStatus.${invoice.status}`),
					invoiceForStudent: formattedInvoiceForStudent,
					isFirstLesson: invoice.isFirstLesson ? '初回' : ''
				}
			})
		},
		// 請求の合計金額
		totalInvoiceForStudent() {
			if (!this.studentInvoices) return '0'

			const total = this.studentInvoices.reduce((accumulator, invoice) => {
				return accumulator + Number(invoice.invoiceForStudent)
			}, 0)

			// 数値をコンマ区切りの文字列に変換して返す
			return total.toLocaleString('ja-JP')
		}
	},
	methods: {
		...mapActions({
			fetchStudentInvoices: 'studentInvoices/fetchStudentInvoices'
		}),
		setUpData() {
			const payload = {
				studentId: this.userInfo.studentId,
				year: this.$moment(this.selectedMonth).format('YYYY'),
				month: this.$moment(this.selectedMonth).format('MM'),
				accessToken: this.userInfo.accessToken
			}
			this.fetchStudentInvoices(payload)
		}
	}
}
</script>

<style lang="scss" module>
.howToPayText {
	background-color: #fff;
	color: #6e6b6b;
	border-radius: 8px;
	padding: 10px;
	font-size: 0.9em;
	margin-bottom: 20px;
}
</style>
<style lang="scss" scoped>
.notification {
	font-size: 0.9em;
	line-height: 2;
	color: #6e6b6b;
}
.aboutLessonStatus {
	margin-bottom: 20px;
}
</style>
