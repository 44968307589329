<template>
	<div class="headerWrapper">
		<div class="header">
			<div class="header__row">
				<div class="logoButton">
					<button @click="toTop" :disabled="!isLogin">
						<v-img
							contain
							:src="require('../../assets/Vollmond_logo.png')"
							class="logo-image"
							:max-width="logoMaxWidth"
						/>
					</button>
					<div>
						<span v-if="isAdmin" class="adminIcon">Admin</span>
					</div>
				</div>
				<v-spacer v-if="isPC" />
				<div v-if="showIllust">
					<v-img
						alt="Vollmond Logo"
						class="cat_boy"
						contain
						:src="require('../../assets/header_cat_and_boy.png')"
						max-width="90"
					/>
				</div>
				<v-spacer v-if="isPC" />
				<div class="ticketAndMenuArea">
					<div class="ticketTextArea" :style="{ width: ticketAreaWidth }" v-if="isShowNumOfTicket">
						<p class="ticketText d-flex align-center">会話チケット: {{ totalAvailableTicketCount }}枚</p>
					</div>
					<v-spacer />

					<v-spacer v-if="!showMenu" />
					<p v-if="canBeAsOtherUser && underLoginAsOtherUser" class="loginUser">
						{{ this.userInfo.userName }} としてログイン中
					</p>
					<!-- メニューアイコン -->
					<div v-if="showMenu" class="menuIcon">
						<HeaderMenuIcon />
					</div>
				</div>
			</div>
		</div>
		<hr />
	</div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import HeaderMenuIcon from '../../components/Molecules/HeaderMenuIcon'

export default {
	name: 'Header',
	components: {
		HeaderMenuIcon
	},
	data: () => ({}),
	created() {},
	mounted() {},
	watch: {
		// $route オブジェクトの変更を監視
		$route(to, from) {
			if (!this.userInfo.accessToken) {
				return
			}

			if (this.userInfo.userAuthority === 'STUDENT') {
				// ユーザーがログインしているかつ、学生の場合にのみチケット情報を取得
				this.fetchConversationTickets({
					accessToken: this.userInfo.accessToken,
					studentId: this.userInfo.studentId
				})
			} else {
				// ユーザーがログインしているかつ、講師の場合にのみレッスンリクエスト情報を取得
				this.fetchWipLessonRequest({
					teacherId: this.userInfo.teacherId,
					accessToken: this.userInfo.accessToken
				})
			}
		}
	},
	computed: {
		...mapGetters({
			userInfo: 'user/getUserInfo',
			totalAvailableTicketCount: 'conversationTickets/totalAvailableTicketCount',
			canAccessLoginAsOtherUser: 'user/canAccessLoginAsOtherUser'
		}),
		containerClass() {
			if (this.isPC) {
				return 'wide-padding'
			} else {
				return 'narrow-padding'
			}
		},
		userName() {
			return this.userInfo.userName ? this.userInfo.userName : '未設定'
		},
		showIllust() {
			return this.isPC
		},
		ticketAreaWidth() {
			if (this.isPC) {
				return '140px'
			} else {
				return '200px'
			}
		},
		showMenu() {
			// ログイン時のみメニューボタンを出す
			if (this.isLogin) {
				return true
			} else {
				return false
			}
		},
		isShowNumOfTicket() {
			// admin画面では表示しない
			if (this.$route.path.match('/admin/*')) {
				return false
			}

			// 会話コースを受講していない生徒には表示しない
			if (!this.subscribeConversation) {
				return false
			}

			if (this.userInfo.userAuthority === 'STUDENT') {
				return true
			} else {
				return false
			}
		},
		subscribeConversation() {
			return this.userInfo.lessonCourseArray.includes('CONVERSATION')
		},
		logoMaxWidth() {
			if (this.isPC) {
				return 160
			} else {
				return 120
			}
		},
		// 「ほかのユーザーとしてログインする」が使えるのはアドミンとその権限が与えられた運用者のみ
		canBeAsOtherUser() {
			return this.isAdmin || (this.isOperator && this.canAccessLoginAsOtherUser)
		},
		isAdmin() {
			if (!this.userInfo) return false
			return this.userInfo.userAuthority === 'ADMIN'
		},
		isOperator() {
			if (!this.userInfo) return false
			return this.userInfo.userAuthority === 'OPERATOR'
		},
		underLoginAsOtherUser() {
			if (!this.userInfo) return false
			return this.userInfo.studentId || this.userInfo.teacherId
		},
		isStudent() {
			return this.userInfo.userAuthority === 'STUDENT'
		}
	},
	methods: {
		...mapActions({
			fetchWipLessonRequest: 'lessonRequest/fetchWipLessonRequest',
			fetchConversationTickets: 'conversationTickets/fetchConversationTickets'
		}),
		toTop() {
			if (this.$route.path != '/studentTop' && this.$route.path != '/teacherTop' && this.$route.path != '/admin/top') {
				if (this.isStudent) {
					this.$router.push('/studentTop')
				} else {
					this.$router.push('/teacherTop')
				}
			}
		}
	}
}
</script>

<style lang="scss" scoped>
.headerWrapper {
	width: 100%;
}
.header {
	height: 70px;
	display: flex;
	justify-content: center;
	@media (max-width: 599px) {
		height: 100px;
	}

	&__row {
		display: flex;
		width: 100%;
		padding: 12px;
		@media (max-width: 599px) {
			flex-direction: column;
		}
		@media (min-width: 960px) {
			width: 900px;
		}
		@media (min-width: 1264px) {
			width: 1185px;
		}
		@media (min-width: 1904px) {
			width: 1785px;
		}
	}
}

.logoButton {
	display: flex;
	align-items: center;
	@media (max-width: 600px) {
		justify-content: space-between;
	}
}
.adminIcon {
	background-color: #f00000;
	font-weight: bold;
	color: white;
	padding: 8px 10px;
	border-radius: 4px;
	font-size: 0.8em;
	margin-left: 12px;
}
.loginUser {
	color: #f00000;
	font-weight: bold;
	font-size: 0.8em;
	margin: 0;
}
.logo-image {
	padding: 30px 0 0 0;
}
.menuIcon {
	margin-right: 12px;
}
.ticketAndMenuArea {
	display: flex;
	align-items: center;

	@media (max-width: 600px) {
		justify-content: space-between;
	}
}
.ticketTextArea {
	display: flex;
	align-items: center;
}
.ticketText {
	font-size: 0.9em;
	margin: auto 0;
	color: #dcc268;
	font-weight: bold;
}
.cat_boy {
	position: relative;
	top: 6px;
	margin-top: 6px;
}
hr {
	height: 2px;
	background-color: #dcc268;
	border: none;
	color: #dcc268;
}

@media screen and (max-width: 599px) {
	hr {
		margin-top: 4px;
	}
}
</style>
